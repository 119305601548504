import React from "react"
import Header from "./Common/Header";
import axios from "axios";
import * as Constants from "./Constants/index"
import { Link } from "react-router-dom";
import Footer from './Common/Footer'
export default class Cinemas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            latitude: '',
            longitude: '',
            searchtxt: '',
            loading: true,
            url: ''
        };
    }
    componentDidMount() {
        const CinemasRequest = async () => {
            var bodyFormData = new FormData();
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("city", localStorage.getItem('city'));
            bodyFormData.append("lat", localStorage.getItem('lat'));
            bodyFormData.append("lng", localStorage.getItem('long'));
            bodyFormData.append("userid", Constants.userid);
            bodyFormData.append("searchtxt", this.state.searchtxt);

            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/alltheater", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.setState({
                                output: resp.data.output.c,
                                url: "https://www.google.com/maps/embed/v1/place?key=AIzaSyCar-wmGPP_TMi_Phi9HoIosnGf3N8ioQ8&q=" + resp.data.output.c[0].lat + ',' + resp.data.output.c[0].lang,
                                loading: false,
                            });
                            console.log(resp);
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        CinemasRequest();
    }
    render() {
        const setmap = (lat, lang) => {
            let url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCar-wmGPP_TMi_Phi9HoIosnGf3N8ioQ8&q=" + lat + ',' + lang
            this.setState({ url: url });
        }
        return (
            <>
                <Header />
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-4">
                            <h2 className="text mt-5 mb-5">CINEMAS</h2>
                            {
                                this.state.output.map((item, key) => {
                                    let url = '/cinemasessions/' + localStorage.getItem('city') + '/' + item.n + '/' + item.cId
                                    return (
                                        <>
                                            <div className="card cinema-card mb-4">
                                                <Link to={url} className='cname'><h5 className="card-header cinema-card-header text">{item.n}</h5></Link>
                                                <div className="card-body">
                                                    <p className="card-text" onClick={() => setmap(item.lat, item.lang)} >{item.ad}</p>
                                                    <Link to={url}>BOOK TICKETS</Link>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className="col mt-5">
                            <p className="mt-5 text">Click on the pointer to check the cinema</p>
                            <div>
                                <iframe src={this.state.url}
                                    style={{ width: "100%", height: "90vh" }}
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
