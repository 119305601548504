import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="container footer-margin text-light mt-5 mb-5 ">
        <div className="row">
          <div className="col-md">
            <h6 className="text-warning">General</h6>
            <ul className="footer-link">
              <li><Link className="nav-text" to="">ABOUT US</Link></li>
              <li><Link className="nav-text" to="">FAQ'S</Link></li>
              <li><Link className="nav-text" to="">INVESTORS SECTION</Link></li>
              <li><Link className="nav-text" to="/careers">CAREER</Link></li>
              <li><Link className="nav-text" to="/news">NEWS</Link></li>
              <li><Link className="nav-text" to="/feedback">FEEDBACK</Link></li>
            </ul>
          </div>
          <div className="col-md pt-5">
            <ul className="footer-link">
              <li><Link className="nav-text" to="/events">EVENTS</Link></li>
              <li><Link className="nav-text" to="">NVSP</Link></li>
            </ul>
          </div>
          <div className="col-md">
            <h6 className="text-warning">OUR BRANDS</h6>
            <ul className="footer-link">
              <li><Link className="nav-text" to="/pvr/format/dircut">PVR DIRECTOR'S CUT</Link></li>
              <li><Link className="nav-text" to="/">PVR PICTURES</Link></li>
              <li><Link className="nav-text" to="/pvr/format/imax">PVR IMAX</Link></li>
              <li><Link className="nav-text" to="/pvr/format/4dx">PVR 4DX</Link></li>
              <li><Link className="nav-text" to="/pvr/format/play-house">PLAYHOUSE</Link></li>
              <li><Link className="nav-text" to="/pvr/format/gold">PVR GOLD</Link></li>
            </ul>
          </div>
          <div className="col-md pt-5">
            <ul className="footer-link">
              <li><Link className="nav-text" to="/pvr/format/luxe">PVR LUXE</Link></li>
              <li><Link className="nav-text" to="/pvr/format/pxl">PVR P[XL]</Link></li>
              <li><Link className="nav-text" to="/pvr/format/onyx">PVR ONYX</Link></li>
              <li><Link className="nav-text" to="">PVR NEST</Link></li>
              <li><Link className="nav-text" to="">V PRISTINE</Link></li>
            </ul>
          </div>
          <div className="col-md">
            <h6 className="text-warning">CINEMAS</h6>
            <ul className="footer-link">
              <li><Link className="nav-text" to="">CINEMAS</Link></li>
              <li><Link className="nav-text" to="/comingsoon">UPCOMING</Link></li>
              <li><Link className="nav-text" to="">ADVERTISE</Link></li>
              <li><Link className="nav-text" to="">BEYOND MOVIES</Link></li>
              <li><Link className="nav-text" to="">BIRTHDAY REQUEST</Link></li>
              <li><Link className="nav-text" to="">SUBSCRIBE TO NEWSLETTER</Link></li>
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}
