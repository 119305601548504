import React from "react"
import * as Constants from "./Constants/index"
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import Button from 'react-bootstrap/Button';
import logo from '../assets/images/logo.png'
import { Link } from "react-router-dom";
import { sha512 } from 'js-sha512';
export default class LoginWithOTP extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            mobile: '',
            showError: false,
            errorMsg: "",
            otpShow: false,
            otp: "",
            show: true,
            close: true,
            isError: {
                mobile: '',
            }
        }
        this.handleChangeMobile = this.handleChangeMobile.bind(this);
    }
    handleChangeMobile(e) {
        this.setState({ mobile: e.target.value });
    }
    componentDidMount() {
        let login = localStorage.getItem('user_id')
        if (!login == '') {
            window.location = '/'
        }
    }
    signup = () => {

        this.setState({ showError: false })
        if (!this.state.otpShow)
            window.location = '/signup'
    }
    logIn = (e) => {
        if (this.validator.allValid()) {
            var bodyFormData = new FormData();
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("city", localStorage.getItem('city'));
            bodyFormData.append("cname", Constants.cname);
            const LoginwithOTPRequest = async () => {
                try {
                    const resp = await axios
                        .post(Constants.baseUrl + "v2/user/login", bodyFormData)
                        .then((resp) => {
                            if (resp.status === 200) {
                                if (resp.data.result == "success") {
                                    if (resp.data.output.nu == "false") {
                                        this.setState({ otpShow: true });
                                    } else {
                                        window.location = '/signup'
                                    }
                                    console.log(resp);
                                    this.setState({
                                        loading: false,
                                    });
                                    this.setState({ errorMsg: resp.data.msg });
                                }
                            }
                        })
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            LoginwithOTPRequest();
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    verifyOTP = (e) => {
        if (this.validator.allValid()) {
            console.log(`Mobile:-${this.state.mobile}`)
            var bodyFormData = new FormData();
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("otp", this.state.otp);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("did", Constants.did);
            let token = this.state.mobile + "|" + this.state.otp;
            token = sha512(token);
            const LoginwithOTPRequest = async () => {
                try {
                    const resp = await axios
                        .post(Constants.baseUrl + "v2/user/verify", bodyFormData, {
                            headers: {
                                "X-Token": token
                            }
                        })
                        .then((resp) => {
                            console.log(resp);
                            if (resp.status === 200) {
                                if (resp.data.result == "success") {
                                    localStorage.setItem('user_email', (resp.data.output.em))
                                    localStorage.setItem('user_id', (resp.data.output.id))
                                    localStorage.setItem('user_phone', (resp.data.output.ph))
                                    localStorage.setItem('user_name', (resp.data.output.un))
                                    // alert("success")
                                    window.location = "/";
                                }
                                else {
                                    this.setState({ errorMsg: resp.data.msg, showError: true });
                                }

                            }
                            else {
                                this.setState({ errorMsg: "Something went wrong", showError: true });
                            }
                        });
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            LoginwithOTPRequest();
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
        this.setState({ otpShow: false })
    }
    render() {
        return (
            <>
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false })}
                />
                <div className="container text bg-dark ">
                    <div className=" text-center">
                        <img src={logo} alt="" />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                        <input type="tel" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.mobile} onChange={this.handleChangeMobile} placeholder="Mobile*"></input><br></br><br></br>
                        {this.validator.message('mobile', this.state.mobile, 'required|min:10|max:10')}
                    </div>
                    {/* <div className="row justify-content-between link">
                        <div className="col-md-6">
                            <Link onClick={() => window.location.back()}>BACK TO LOGIN</Link>
                        </div>
                    </div> */}
                    <div className="text-center mt-3">
                        <button onClick={this.logIn} className="btn submit">Login</button>
                    </div>
                    <Link to='/signup'>
                        <div className="text-center pb-5">
                            <button className="btn submit">Go to Signup</button>
                        </div>
                    </Link>
                </div>
                {
                    this.state.otpShow ?
                        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Enter your OTP</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <OtpInput
                                    value={this.state.otp}
                                    onChange={(otp) => this.setState({ otp })}
                                    numInputs={6}
                                    isInputNum={true}
                                    separator={<span> </span>}
                                />
                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="primary" onClick={() => this.verifyOTP()}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        : ''}
            </>
        )
    }
}