import React from "react";
import logo from "../../assets/images/new_logo.png"
import Search from "../Search";
import Cities from "../Cities";
import { Link } from "react-router-dom";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  logout = () => {
    localStorage.clear();
    window.location = '/'
    // window.location.reload(false);
  }
  componentDidMount() {
    console.log(localStorage.getItem("user_id"));
  }
  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light background">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1 col-sm-12">
                <Link className="navbar-brand" to="/"><img src={logo} to="" alt="" width="65" height="65"></img></Link>
              </div>
              <div className="col-md-4">
                <Search />
              </div>
              <div className="col-md-3">
                <Cities />
              </div>
              {!localStorage.getItem('user_id') || localStorage.getItem('user_id') === '' || localStorage.getItem('user_id') === null || localStorage.getItem('user_id') === undefined ?
                <div className="col-md-4">
                  <div className="name-show">
                    <div className="name-list">
                      <h6 className="text">Hello!</h6>
                    </div>
                    <div className="login-start">
                      <Link className="logout" to="/login">Login</Link>
                    </div>
                  </div>
                </div>
                :
                <div className="col-md-4">
                  <div className="name-show">
                    <div className="name-list">
                      <h6 className="text">{localStorage.getItem("user_name")}</h6>
                    </div>
                    <div className="login-start">
                      <Link className="logout" onClick={this.logout} >Logout</Link>
                    </div>
                  </div>
                </div>
              }
              <div className="col-md-12">
                <button className="navbar-toggler bg-warning" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon text-warning"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/imax">IMAX</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/4dx">4DX</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/play-house"> Playhouse</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/gold">GOLD</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/luxe">LUXE</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/pxl">P[XL]</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/onyx">ONYX</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/dit">Drive-In</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/pvr/format/dircut">Director's Cut</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="">PVR Cares</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/cinemas/Delhi-NCR">Cinemas</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/offers">Offers</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="#">Advertise</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/giftcards">Gift Cards</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="#">Movie On Demand </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="/movie-library">Movie Library</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link nav-text text" to="#">V PRISTINE</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>

      </>
    );
  }
}
