import React from "react"
import Header from "./Common/Header"
import Footer from "./Common/Footer";
import * as Constants from "./Constants/index"
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import dx from '../assets/images/4dx.png'
import dx_banner from '../assets/images/banners/4DXbanner.jpg'
export default class Dx extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            show: false,
            close: true,
            mtrailerurl: "",
            loading: true
        }
    }
    //Model
    handleShow = (trailer) => {
        //        e.preventDefault()
        this.setState({ show: true, mtrailerurl: trailer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("type", "4DX");
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("city", localStorage.getItem('city'));

        const DxRequest = async () => {
            try {
                const resp = await axios
                    .post(
                        Constants.baseUrl + "content/specialcine",
                        bodyFormData
                    )
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output.cinemas.m,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        DxRequest();
    }
    render() {
        return (
            <>
                <Header />
                <Carousel>
                    {
                        this.state.output.map((item, key) => {
                            let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n + "/" + item.id + "?language=" + item.lng + "&cinemaType=4DX"
                            let url2 = url.replace(/\s/g, "-")
                            return (
                                <>
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img class="d-block w-100" src={item.wib} alt="First slide" />
                                                <div class="carousel-caption d-none d-md-block">
                                                    <h1>{item.n}</h1>
                                                    <div className="cinema-banner-btn">
                                                        <Link to={url2}>
                                                            <Button variant="light">
                                                                BOOK TICKETS
                                                            </Button>
                                                        </Link>&nbsp;
                                                        <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                                            PLAY TRAILER
                                                        </Button>
                                                    </div>
                                                    <Modal
                                                        show={this.state.show}
                                                        onHide={this.state.handleClose}
                                                        backdrop="static"
                                                        keyboard={false}
                                                    >
                                                        <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ReactPlayer controls url={this.state.mtrailerurl} />
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src={dx_banner} alt="First slide" />
                            </div>
                        </div>
                    </div>
                </Carousel>
                <div className="container mb-5">
                    {this.state.output.length > 0 ? <h2 className="text mb-5">NOW SHOWING (IN 4DX)</h2> : ''}
                    <div className="row ">
                        {
                            this.state.output.map((item, key) => {
                                let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n.replace(' ', '-') + "/" + item.id + "?language=" + item.lng + "&cinemaType=4DX"
                                let url2 = url.replace(/\s/g, "-")
                                return (
                                    <>
                                        <div className="col-md-3">
                                            <div className="card bg-dark mb-4 pb-2">
                                                <div className="card-body ">
                                                    <img id="image" src={item.wit} alt="" srcSet="" className="mw-100" />
                                                    <div class="movie-name mt-2">
                                                        <h6 className="text">{item.n}</h6>
                                                        <span className='text'>{item.lng} . {item.ce} . {item.tag}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='mx-0.5'>
                                                        <Link to={url2}>
                                                            <Button variant="light">
                                                                BOOK TICKETS
                                                            </Button>
                                                        </Link>&nbsp;
                                                        <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                                            PLAY TRAILER
                                                        </Button>

                                                        <Modal
                                                            show={this.state.show}
                                                            onHide={this.state.handleClose}
                                                            backdrop="static"
                                                            keyboard={false}
                                                        >
                                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <ReactPlayer controls url={this.state.mtrailerurl} />
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div >
                <div className="container mb-5">
                    <img src={dx} alt="no-image" height="30" />
                    <h5 className="text mt-3 mb-4">ABSOLUTE CINEMA EXPERIENCE</h5>
                    <p className="text">4DX changes the cinematic paradigm from just Watching to Experiencing. The revolutionary realistic effects stimulates all five senses with high-tech motion seats and special effects including wind, fog, lightning, bubbles, water, rain and scents, in both 2D and 3D formats. These effects work in perfect synchronicity with the action on screen - creating the most un-miss able and exhilarating cinematic experience yet. Movies are no longer confined to the screen. 4DX draws you into the movie as if you’re living inside its world.</p>
                </div>
                <Footer />
            </>
        )
    }
}