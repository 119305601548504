import React from "react"
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Header from "./Common/Header";
export default class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            imageUrl: "",
            title: "",
            loding: false
        }
    }
    //Model
    handleShow = (pvrnews, newstitle) => {
        //        e.preventDefault()
        this.setState({ show: true, imageUrl: pvrnews, title: newstitle })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        const NewsRequest = async () => {
            try {
                const resp = await axios
                    .post("https://demoapi.pvrcinemas.com/PVRCinemasCMS/getnews")
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        NewsRequest();
    }
    render() {
        return (
            <>
                <Header />
                <div className="container text">
                    <h4 className="mb-5 mt-5">PVR NEWS</h4>
                    <div className="row">
                        {
                            this.state.output.map((item, i) => {
                                return (
                                    <>
                                        <div className="col-sm-4">
                                            <img src={item.imageUrl} alt="no image" srcSet="" className="w-50 d-inline-block mb-5" onClick={() => this.handleShow(item.imageUrl, item.title)} />
                                            <p style={{ fontSize: "1rem" }}>{item.title}</p>
                                        </div>
                                        <Modal
                                            show={this.state.show}
                                            onHide={this.state.handleClose}
                                            backdrop="static"
                                            keyboard={false}

                                        >
                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                            </Modal.Header>
                                            <Modal.Body style={{ height: "100vh", overflowY: 'scroll' }}>
                                                <img src={this.state.imageUrl} alt="" srcSet="" className="w-100" />
                                                <h3>{this.state.title}</h3>
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}
