import React, { Component } from 'react'
import Header from './Common/Header'
import Footer from './Common/Footer'
import Privilege_img from '../assets/images/privilege-img.png'
import join_card_img from '../assets/images/p-privilege.png'
import privilege_icon from '../assets/images/privilege-icon.png'
import gift_card_icon from '../assets/images/gift-card-icons.png'
import icon_king from '../assets/images/icons-king.png'
import pvr_kotak_card from '../assets/images/PVR_Kotak_Card.png'
export default class Loyalty extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container privilege-loyalty">
                    <div className="privilege-first-card">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className='text first-card'>Experience a new Privilege at PVR</h1>
                                <p className='text'>Get set to be rewarded on every transaction you make at PVR Cinemas or on our website. You earn 5 PVR Privilege Points for every INR 100 you spend on tickets & food & beverage items.</p>
                            </div>
                            <div className="col-md-6">
                                <img src={Privilege_img} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="privilege-card">
                        <h2 className='text'>Pick your privilege card</h2>
                    </div>
                    <div className="privilege-join-card">
                        <div className="privilege-join-card-box">
                            <div className="privilege-join-card-img">
                                <img src={join_card_img} alt="" />
                            </div>
                            <div className="join-card-content">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="feature-box">
                                            <ul className="pointslist">
                                                <li>
                                                    <img src={privilege_icon} alt="" />
                                                    <p className='text'>Earn points & vouchers</p>
                                                </li>
                                                <li>
                                                    <img src={gift_card_icon} alt="" />
                                                    <p className='text'>Redeem your rewards</p>
                                                </li>
                                                <li>
                                                    <img src={icon_king} alt="" />
                                                    <p className='text'>Invite to special screenings</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li className='text'>Free Privilege Popcorn (Salted on your first visit after joining)</li>
                                                <li className='text'>5% Points on every ₹100 spent on tickets or snacks</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3 join-card">
                                        <button type="button" class="btn btn-warning">Join Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="privilege-apply-card">
                        <div className="privilege-join-card-box">
                            <div className="privilege-join-card-img">
                                <img src={pvr_kotak_card} alt="" />
                            </div>
                            <div className="join-card-content">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="feature-box">
                                            <ul className="pointslist">
                                                <li>
                                                    <img src={privilege_icon} alt="" />
                                                    <p className='text'>Earn points & vouchers</p>
                                                </li>
                                                <li>
                                                    <img src={gift_card_icon} alt="" />
                                                    <p className='text'>Redeem your rewards</p>
                                                </li>
                                                <li>
                                                    <img src={icon_king} alt="" />
                                                    <p className='text'>Invite to special screenings</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li className='text'>PVR Food & Beverage Vouchers of Rs. 500 on joining.</li>
                                                <li className='text'>Upto 10% points on PVR transactions & 0.5% points on Non-PVR transactions.</li>
                                            </ul>
                                            <div className="apply-card">
                                                <p className='text reward-heading'>Earn more rewards (10 Points on every ₹ 100) with <span className='text-danger'>PVR Privilege</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 join-card">
                                        <button type="button" class="btn btn-warning">Apply Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
