// export const baseUrl = "https://demoapi.pvrcinemas.com/PVRCinemasCMS/api/";
export const baseUrl = "https://api1.pvrcinemas.com/PVRCinemasCMS/api/";
export const av = "1.0";
export const pt = "WEBSITE";
export const did = "96493311451669194808231";
export const userid = "0"
export const dtmsource = "null"
export const lang = "ALL"
export const clientId = "658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
export const appId = "1088597931155576"
export const cname = "INDIA"