import React from "react"
import axios from "axios";
import * as Constants from "./Constants/index";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
export default class ComingSoon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            show: false,
            close: true,
            videoUrl: "",
            visible: 8,
            loading: true
        }
    }
    //modal
    handleShow = (trailer) => {
        this.setState({ show: true, videoUrl: trailer })
    }
    showMoreitems = () => {
        this.setState((old) => {
            return { visible: old.visible + 1000 }
        })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("lang", Constants.lang);
        const ComingSoonRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/comingsoon", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        ComingSoonRequest();
    }
    render() {
        return (
            <>
                <div className="container">
                    <h2 className="text mb-5 mt-4">COMING SOON</h2>
                    <div className="row">
                        {
                            this.state.output.slice(0, this.state.visible).map((item, key) => {
                                let url = "/comingsoon/" + localStorage.getItem('city') + "/" + item.name + "/" + item.masterMovieId
                                let url2 = url.replace(/\s/g, "-")
                                return (
                                    <>
                                        <div className="col-md-3">
                                            <div className="card bg-dark mb-4 pb-2">
                                                <div className="card-body">
                                                    <img src={item.wit} alt="" srcSet="" className="mw-100" />
                                                    <div className="movie-name">
                                                        <h6 className=" text">{item.name}</h6>
                                                        <span className='text'>{item.language} . {item.genre}</span><br></br>
                                                        <span className='text'>{item.date_caption}</span>
                                                    </div>
                                                </div>
                                                <div className='mx-2'>
                                                    <Link to={url2}>
                                                        <Button variant="light">
                                                            SHOW INFO
                                                        </Button>
                                                    </Link>&nbsp;
                                                    <Button variant="light" onClick={() => this.handleShow(item.videoUrl)}>
                                                        PLAY TRAILER
                                                    </Button>
                                                    <Modal
                                                        show={this.state.show}
                                                        onHide={() => this.setState({ show: false })}
                                                        dialogClassName="modal-90w"
                                                        aria-labelledby="example-custom-modal-styling-title"
                                                    >
                                                        <Modal.Header closeButton>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ReactPlayer url={this.state.videoUrl} />
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <button className="btn btn-view text" onClick={() => this.showMoreitems()}>VIEW ALL</button>
                    </div>
                </div>
            </>
        );
    }
}



