import React from 'react'
import axios from 'axios';
import * as Constants from '../Component/Constants/index'
import { FaMinus } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
export default class GetFood extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            output2: [],
            ticketDetails: '',
            category: [],
            filterCategorySelected: '',
            foodDetails: [],
            selectedFood: [],
            count: "",
            loading: true
        }
    }
    skipfood = () => {
        window.location = '/payment/' + localStorage.getItem('cinema_name')
    }
    addFood = (food) => {
        let total_quantity = 0;
        this.state.selectedFood.map((item, key) => {
            total_quantity = total_quantity + item.qt;
        });
        if (total_quantity >= 5) {
            alert('add only 5 item/quantity');
        } else {
            if (this.state.selectedFood.includes(food)) {
                food.qt += 1;
                this.setState({ count: food.qt })
            } else {
                this.state.selectedFood.push(food);
                food.qt += 1
                this.setState({ count: food.qt })
            }
            console.log(this.state.selectedFood)
        }
    }
    deleteFood = (food) => {
        if (this.state.selectedFood.includes(food)) {
            food.qt -= 1;
            this.setState({ count: food.qt })
        } else {
            food.qt -= 1
            this.setState({ count: food.qt })
        }
        if (food.qt === 0) {
            const objWithIdIndex = this.state.selectedFood.findIndex((obj) => obj.id === food.id);
            if (objWithIdIndex > -1) {
                this.state.selectedFood.splice(objWithIdIndex, 1);
            }
        }
        console.log(this.state.selectedFood)
    }

    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("ccode", localStorage.getItem('ccode'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("bookingid", localStorage.getItem('bookingid'));
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("audi", " ");
        bodyFormData.append("seat", " ");
        bodyFormData.append("iserv", " ");
        const foodRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "food/getfoods", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.setState({
                                output: resp.data.output.cat,
                                output2: resp.data.output.r,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        }
        foodRequest();

        var bodyFormData = new FormData();
        bodyFormData.append("transid", localStorage.getItem('transid'));
        bodyFormData.append("cinemacode", localStorage.getItem('ccode'));
        bodyFormData.append("userid", localStorage.getItem('user_id'));
        bodyFormData.append("bookingid", localStorage.getItem('bookingid'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("pt", Constants.pt);
        const ticketRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "trans/tckdetails", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (resp.data.result === "success") {
                                this.setState({
                                    ticketDetails: resp.data.output.a,
                                })
                                console.log(resp);
                            }
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        }
        ticketRequest();
    }

    setFood = () => {

        let foods = '';
        this.state.selectedFood.map((foodrow, index) => {

            if (index === 0) {
                foods += foodrow.h + '|' + foodrow.id + '|' + foodrow.qt + '|' + foodrow.dp + '|' + foodrow.ho;
            } else {
                foods += '#' + foodrow.h + '|' + foodrow.id + '|' + foodrow.qt + '|' + foodrow.dp + '|' + foodrow.ho;
            }
        });

        var bodyFormData = new FormData();
        bodyFormData.append("transid", localStorage.getItem('transid'));
        bodyFormData.append("cinemacode", localStorage.getItem('ccode'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("foods", foods);

        const setFoodRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "trans/setfood", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.setState({
                                loading: false,
                            });
                            window.location = '/payment/' + localStorage.getItem('cinema_name')
                            console.log(resp)
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        }
        setFoodRequest();
    }

    filterCategory = (foodName) => {
        this.setState({ filterCategorySelected: foodName })
    }

    render() {
        let filterCategorySelected = this.state.filterCategorySelected
        let total_quantity = 0;
        let foodAmount = 0.00
        this.state.selectedFood.map((item, key) => {
            var calculateamnt = (item.dp * item.qt) / 100;
            total_quantity = total_quantity + item.qt;
            foodAmount = parseFloat(foodAmount + calculateamnt);
        });
        var totalamnt = 0.00;
        var preamount = this.state.ticketDetails;
        totalamnt = parseFloat(foodAmount) + parseFloat(preamount);

        return (
            <>
                <div className="container">
                    <ul className="foodNavbar">
                        <li className="food-nav-item" onClick={() => this.filterCategory("")}>ALL</li>
                        {
                            this.state.output.map((f, key) => {
                                return (
                                    <>
                                        <li className="food-nav-item" onClick={() => this.filterCategory(f)}>{f}</li>
                                    </>
                                )
                            })}
                    </ul>
                    <button type="button" className="btn btn-warning" onClick={this.skipfood}>Continue</button>
                    <div className="row foodContainer">
                        {
                            this.state.output2.map((item, i) => {
                                if (item.ct === filterCategorySelected || filterCategorySelected === '') {
                                    return (
                                        <>
                                            <div className="col-md-4 mt-3 mb-3" key={i}>
                                                <img src={item.iw} className='foodImage' alt='no food' style={{ width: "100%", height: "35vh" }} />
                                                <h6 className='text mt-2'>{item.h}</h6>
                                                <h5 className='foodPrice'>{item.dp / 100}</h5>
                                                {item.qt > 0 ?
                                                    <div className='incre-decre'>
                                                        <button onClick={() => this.deleteFood(item)}><FaMinus /></button>
                                                        <span className='text'>{item.qt}</span>
                                                        <button onClick={() => this.addFood(item)} ><FaPlus /></button>
                                                    </div> :
                                                    <button type="button" className="btn btn-light addFood" onClick={() => this.addFood(item)}>ADD</button>
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <button className="btn btn-light bg-light position-fixed bottom-0 start-0" onClick={() => window.history.back()}>prev</button>
                <div className="container bg-light position-fixed bottom-0 end-0 foodDetails rounded-top">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="seat-info">
                                <h5>Food</h5>
                                {
                                    this.state.selectedFood.map((item, key) => {
                                        return (
                                            <>
                                                <img src={item.si} alt="" style={{ width: '50px' }} />
                                                <p>{item.qt}</p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="payment-infos">
                                <div className="show-total">
                                    <p>TotaL [INR]</p>
                                </div>
                                <div className="amount-show">
                                    <p>{parseFloat(totalamnt).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="btn btn-warning position-fixed bottom-0 end-0" onClick={this.setFood}>Next</button>
                </div>
            </>
        )
    }
}