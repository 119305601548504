import React from "react"
import Header from "./Common/Header"
import Footer from './Common/Footer'
import * as Constants from "./Constants/index"
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import imax from '../assets/images/imax.png'
import imax_banner from '../assets/images/banners/imax-banner.jpg'
export default class Imax extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            show: false,
            close: true,
            mtrailerurl: "",
            loading: true
        }
    }
    //Model
    handleShow = (trailer) => {
        //        e.preventDefault()
        this.setState({ show: true, mtrailerurl: trailer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("type", "IMAX");
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("city", localStorage.getItem('city'));

        const ImaxRequest = async () => {
            try {
                // const token = localStorage.getItem("token");

                const resp = await axios
                    .post(Constants.baseUrl + "content/specialcine", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output.cinemas.m,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        ImaxRequest();
    }
    render() {
        return (
            <>
                <Header />
                <Carousel>
                    {
                        this.state.output.map((item, key) => {
                            let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n + "/" + item.id + "?language=" + item.lng + "&cinemaType=IMAX"
                            let url2 = url.replace(/\s/g, "-")
                            return (
                                <>
                                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img class="d-block w-100" src={item.wib} alt="First slide" />
                                                <div class="carousel-caption d-none d-md-block">
                                                    <h1>{item.n}</h1>
                                                    <div className="cinema-banner-btn">
                                                        <Link to={url2}>
                                                            <Button variant="light">
                                                                BOOK TICKETS
                                                            </Button>
                                                        </Link>&nbsp;
                                                        <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                                            PLAY TRAILER
                                                        </Button>
                                                    </div>
                                                    <Modal
                                                        show={this.state.show}
                                                        onHide={this.state.handleClose}
                                                        backdrop="static"
                                                        keyboard={false}
                                                    >
                                                        <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ReactPlayer controls url={this.state.mtrailerurl} />
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src={imax_banner} alt="First slide" />
                            </div>
                        </div>
                    </div>
                </Carousel>
                <div className="container mb-5">
                    {this.state.output.length > 0 ? <h2 className="text mb-5">NOW SHOWING (IN IMAX)</h2> : ''}
                    <div className="row ">
                        {
                            this.state.output.map((item, key) => {
                                let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n.replace(' ', '-') + "/" + item.id + "?language=" + item.lng + "&cinemaType=4DX"
                                let url2 = url.replace(/\s/g, "-")
                                return (
                                    <>
                                        <div className="col-md-3">
                                            <div className="card bg-dark mb-4 pb-2">
                                                <div className="card-body ">
                                                    <img id="image" src={item.wit} alt="" srcSet="" className="mw-100" />
                                                    <div class="movie-name mt-2">
                                                        <h6 className="text">{item.n}</h6>
                                                        <span className='text'>{item.lng} . {item.ce} . {item.tag}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='mx-0.5'>
                                                        <Link to={url2}>
                                                            <Button variant="light">
                                                                BOOK TICKETS
                                                            </Button>
                                                        </Link>&nbsp;
                                                        <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                                            PLAY TRAILER
                                                        </Button>

                                                        <Modal
                                                            show={this.state.show}
                                                            onHide={this.state.handleClose}
                                                            backdrop="static"
                                                            keyboard={false}
                                                        >
                                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <ReactPlayer controls url={this.state.mtrailerurl} />
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div >
                <div className="container mb-5">
                    <img src={imax} alt="no-image" height="30" />
                    <h5 className="text mt-3 mb-4">THE WORLD'S MOST IMMERSIVE MOVIE EXPERIENCE</h5>
                    <p className="text">Movies are watched. But seeing a movie in an IMAX® theatre is so much more. It’s what we call - The IMAX Experience®. Every element in the IMAX theatre is planned, designed and positioned with exacting standards to create the most intense experience. This science is our theatre geometry, and it ensures movie magic every time the lights go down.</p>
                    <p className="text">IMAX is an entertainment experience so real you feel it in your bones, so magical it takes you places you have never been; so all-encompassing you're not just peeking through the window, but part of the action. IMAX draws you into something as close to reality as you have ever experienced.</p>
                </div>
                <Footer />
            </>
        )
    }
}