import React from "react"
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Header from "../Component/Common/Header"
import SweetAlert from 'react-bootstrap-sweetalert';
export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            imageUrl: "",
            description: "",
            showError: false,
            errorMsg: "",
            loding: false
        }
    }
    //Model
    handleShow = (eventsImg, eventsDesc) => {
        this.setState({ show: true, imageUrl: eventsImg, description: eventsDesc })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("city", "" + localStorage.getItem('city') + "");
        const EventsRequest = async () => {
            try {
                const resp = await axios
                    .post("https://api1.pvrcinemas.com/PVRCinemasCMS/getevent", bodyFormData
                    )
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (resp.data.result == "success") {
                                console.log(resp);
                                this.setState({
                                    output: resp.data.output.events,
                                    loading: false,
                                });
                                this.setState({ errorMsg: resp.data.msg, showError: true });
                            } else {
                                this.setState({ errorMsg: resp.data.msg, showError: true });
                            }
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        EventsRequest();
    }
    render() {
        return (
            <>
                <Header />
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false },
                    )}
                />
                <div className="container-fluid">
                    <h1 className="text mt-3 mb-3">PVR Events</h1>
                    <div className="row">
                        {
                            this.state.output.map((item, i) => {
                                return (
                                    <>
                                        <div className="col-sm-4">
                                            <img src={item.imageUrl} alt="no image" srcSet="" className="w-50 h- d-inline-block mb-5" onClick={() => this.handleShow(item.imageUrl, item.description)} />
                                            <p style={{ fontSize: "1rem" }}>{item.title}</p>
                                            <p style={{ fontSize: "1rem" }}>{item.strCinemaDetail}</p>
                                            <p style={{ fontSize: "1rem" }}>{item.eventDate}</p>
                                        </div>
                                        <Modal
                                            show={this.state.show}
                                            onHide={this.state.handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                            </Modal.Header>
                                            <Modal.Body style={{ height: "100vh", overflowY: 'scroll' }}>
                                                <img src={this.state.imageUrl} alt="" srcSet="" className="w-100" />
                                                <h3>{this.state.description}</h3>
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}
