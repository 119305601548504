import React from "react";
import '../assets/css/style.css'
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import * as Constants from "./Constants/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      output: [],
      show: false,
      close: true,
      mtrailerurl: "",
      autoplay: true,
      loading: true,
    };
  }
  //Model
  handleShow = (trailer) => {
    //        e.preventDefault()
    this.setState({ show: true, mtrailerurl: trailer, autoplay: false })
  }

  componentDidMount() {
    var bodyFormData = new FormData();
    bodyFormData.append("type", "DC");
    bodyFormData.append("city", "" + localStorage.getItem('city') + "");
    bodyFormData.append("av", Constants.av);
    bodyFormData.append("pt", Constants.pt);
    bodyFormData.append("did", Constants.did);

    const bannerRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.baseUrl + "content/bannersnew", bodyFormData)
          .then((resp) => {
            if (resp.status == 200) {
              console.log(resp);
              this.setState({
                output: resp.data.output,
                loading: false,
              });
            }
          });
      } catch (err) {
        // Handle Error Here
        this.setState({
          loading: false,
        });
        console.error(err);
      }
    };
    bannerRequest();
  }
  render() {
    return (
      <>
        <Carousel autoPlay={this.state.autoplay}>
          {
            this.state.output.map((item, key) => {
              let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n + "/" + item.id + "?language=" + item.lng + "&cinemaType=4DX"
              let url2 = url.replace(/\s/g, "-")
              return (
                <>
                  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img className="d-block w-100" src={item.wib} alt="First slide" />
                        {item.n != '' ?
                          <div className="carousel-caption d-none d-md-block">
                            <h1 className='m-name'>{item.n}</h1>
                            <div className="banner-btn">
                              <Link to={url2}>
                                <Button variant="light">
                                  BOOK TICKETS
                                </Button>
                              </Link>&nbsp;&nbsp;&nbsp;
                              <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                PLAY TRAILER
                              </Button>
                            </div>
                            <Modal
                              show={this.state.show}
                              onHide={this.state.handleClose}
                              backdrop="static"
                              keyboard={false}
                            >
                              <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                              </Modal.Header>
                              <Modal.Body>
                                <ReactPlayer controls url={this.state.mtrailerurl} />
                              </Modal.Body>
                            </Modal>
                          </div> : ''}
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
        </Carousel>
      </>
    );
  }
}
