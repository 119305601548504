import React from "react"
import * as Constants from "./Constants/index"
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import logo from '../assets/images/logo.png'
import { Link } from "react-router-dom";
import { sha512 } from 'js-sha512';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import Button from 'react-bootstrap/Button';
export default class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            name: '',
            email: '',
            mobile: '',
            otp: '',
            // conPass: '',
            loading: true,
            showError: false,
            errorMsg: "",
            show: false,
            isError: {
                name: '',
                email: '',
                mobile: '',
                password: '',
                conPass: ''
            }
        }
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMobile = this.handleChangeMobile.bind(this);
        this.handleChangeOtp = this.handleChangeOtp.bind(this);
        this.handleChangeConPass = this.handleChangeConPass.bind(this);
    }
    handleChangeName = (e) => {
        this.setState({ name: e.target.value });
    }
    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    handleChangeMobile = (e) => {
        this.setState({ mobile: e.target.value });
    }
    handleChangeOtp = (e) => {
        this.setState({ otp: e.target.value });
    }
    handleChangeConPass = (e) => {
        this.setState({ conPass: e.target.value });
    }

    verifyOTP = (e) => {
        // e.preventDefault()
        if (this.validator.allValid()) {
            // console.log(`Name:-${localStorage.getItem('name')}   Email:-${this.state.email}  Mobile:-${this.state.mobile} Password:-${this.state.password} Confirm Password:-${this.state.conPass}`)
            var bodyFormData = new FormData();
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("otp", this.state.otp);
            bodyFormData.append("name", this.state.name);
            bodyFormData.append("email", this.state.email);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("cname", Constants.cname);
            let token = this.state.mobile + "|" + this.state.otp;
            token = sha512(token);
            const SignUpRequest = async () => {
                try {
                    const resp = await axios
                        .post(Constants.baseUrl + "v2/user/register", bodyFormData, {
                            headers: {
                                "X-Token": token
                            }
                        })
                        .then((resp) => {
                            if (resp.status === 200) {
                                if (resp.data.result === "success") {
                                    // console.log(resp);
                                    this.setState({
                                        loading: false,
                                    });
                                    this.setState({ errorMsg: resp.data.msg, showError: true });
                                }
                                else {
                                    this.setState({ errorMsg: resp.data.msg, showError: true });
                                }
                            }
                            else {
                                this.setState({ errorMsg: resp.data.msg, showError: true });
                            }
                        });
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            SignUpRequest();
        }
        else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    render() {

        return (
            <>
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false },
                    )}
                />

                <div className="container text bg-dark pb-5">
                    <div className=" text-center">
                        <img src={logo} alt="" />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                        <input type="name" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.name} onChange={this.handleChangeName} placeholder="Full Name*" /><br></br><br></br>
                        {this.validator.message('Name', this.state.name, 'required')}
                    </div>
                    <div className="input-group input-group-outline mb-3">
                        <input type="email" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email*" /><br></br><br></br>
                        {this.validator.message('Email', this.state.email, 'required')}
                    </div>
                    <div className="input-group input-group-outline mb-3">
                        <input type="tel" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.mobile} onChange={this.handleChangeMobile} placeholder="Mobile*" /><br></br><br></br>
                        {this.validator.message('Mobile', this.state.mobile, 'required|min:10|max:10')}
                    </div>
                    <div className="input-group input-group-outline mb-3">
                        <input type="number" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.otp} onChange={this.handleChangeOtp} placeholder="OTP*" /><br></br><br></br>
                        {this.validator.message('OTP', this.state.otp, 'required')}
                    </div>
                    {/* <div className="input-group input-group-outline mb-3">
                        <input type="Password" className="w-50 bg-dark text-light giftcard-form-border" value={this.state.conPass} onChange={this.handleChangeConPass} placeholder="Confirm Password*" /><br></br><br></br>
                        {this.validator.message('Confirm Password', this.state.conPass, 'required')}
                    </div> */}
                    <div className="row justify-content-between link">
                        <div className="col-md-6">
                            <Link to='/login'>BACK TO LOGIN</Link>
                        </div>
                    </div>
                    <div className="text-center">
                        <button onClick={() => this.setState({ show: true })} className="btn submit">Submit</button>
                    </div>
                </div>
                {
                    this.state.show === true ?
                        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Enter your OTP</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <OtpInput
                                    value={this.state.otp}
                                    onChange={(otp) => this.setState({ otp })}
                                    numInputs={6}
                                    isInputNum={true}
                                    separator={<span> </span>}
                                />
                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="primary" onClick={() => this.verifyOTP()}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        : ''}
            </>
        )
    }
}