import React from 'react'
import SimpleReactValidator from 'simple-react-validator';
import Header from "../Component/Common/Header"
import * as Constants from "./Constants/index"
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import Footer from './Common/Footer';
export default class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            userName: "",
            mobile: "",
            email: "",
            customerComments: "",
            copySelf: "0",
            show: false,
            msg: "",
            feedbackType: ""
        }
        this.handleuserName = this.handleuserName.bind(this);
        this.handleMobile = this.handleMobile.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlecustomerComments = this.handlecustomerComments.bind(this);
    }
    handleuserName = (e) => {
        this.setState({ userName: e.target.value });
    }
    handleMobile = (e) => {
        this.setState({ mobile: e.target.value });
    }
    handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    handlecustomerComments = (e) => {
        this.setState({ customerComments: e.target.value });
    }
    selectOnlyThis = (type) => {
        this.setState({ feedbackType: type })
    }
    sendRequest = () => {
        if (this.validator.allValid()) {
            console.log(`Name:-${this.state.userName} Mobile:-${this.state.mobile} Email:-${this.state.email} Message:-${this.state.customerComments}`)
            var bodyFormData = new FormData();
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("platform", Constants.pt);
            bodyFormData.append("userName", this.state.userName);
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("customerComments", this.state.customerComments);
            bodyFormData.append("email", this.state.email);
            bodyFormData.append("copySelf", this.state.copySelf);
            bodyFormData.append("feedbackType", this.state.feedbackType);
            const feedbackRequest = async () => {
                try {
                    const resp = await axios
                        .post("https://demoapi.pvrcinemas.com/PVRCinemasCMS/addconnect", bodyFormData)
                        .then((resp) => {
                            //  casetype array
                            if (resp.status === 200) {
                                if (resp.data.result == "success") {
                                    console.log(resp);
                                    this.setState({
                                    });
                                }
                                this.setState({ msg: resp.data.msg, show: true });
                            }
                        });
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            feedbackRequest();
        }
        else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    render() {
        return (
            <>
                <Header />
                <SweetAlert
                    show={this.state.show}
                    title={this.state.msg}
                    html
                    text={this.state.msg}
                    onConfirm={() => this.setState({ show: false },
                    )}
                />
                <div className="container gift-container-bg text-light w-50 pb-5">
                    <h3 className="text-light pb-5 pt-4">FEEDBACK</h3>
                    <input type="text" className="w-100 text-light giftcard-form-border" value={this.state.userName} onChange={this.handleuserName} placeholder="User Name"></input><br></br><br></br>
                    {this.validator.message('Name', this.state.userName, 'required')}
                    <input type="tel" className="w-100  text-light giftcard-form-border" value={this.state.mobile} onChange={this.handleMobile} placeholder="Enter Mobile"></input><br></br><br></br>
                    {this.validator.message('mobile', this.state.mobile, 'required|min:10')}
                    <input type="email" className="w-100  text-light giftcard-form-border" value={this.state.email} onChange={this.handleEmail} placeholder="Enter Email"></input><br></br><br></br>
                    {this.validator.message('Email', this.state.email, 'required|email')}
                    <textarea className="form-control giftcard-form-border" id="exampleFormControlTextarea1" value={this.state.customerComments} onChange={this.handlecustomerComments} placeholder="Message"></textarea><br></br><br></br>
                    <h4>Type:</h4><br></br>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("complaint")} />
                        <label>Complaint</label>
                    </div>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("suggestion")} />
                        <label>Suggestion</label>
                    </div>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("compliment")} />
                        <label>Compliment</label>
                    </div>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("enquiry")} />
                        <label>Enquiry</label>
                    </div>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("others")} />
                        <label>Others</label>
                    </div>
                    <div>
                        <input type="checkbox" onClick={() => this.selectOnlyThis("copy To self")} />
                        <label>Copy To Self</label>
                    </div>
                    <button type="button" onClick={this.sendRequest} className="btn btn-warning w-50 mt-4">SEND REQUEST</button>
                </div>
                <Footer />
            </>
        )
    }
}