import React from 'react'
import axios from "axios";
import * as Constants from "./Constants/index"
import Header from "./Common/Header";
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
export default class CinemaSessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: {},
            img: [],
            dys: [],
            childs: [],
            loading: true,
            date: 'NA',
            cid: (window.location.href.split('/')[6]).split('?')[0],
            showError: false,
            errorMsg: "",
        }
    }
    changeDate = (date) => {

        this.setState({ date }, this.cinemaSessionsRequest);
    }
    cinemaSessionsRequest = async () => {
        var bodyFormData = new FormData();
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("cid", this.state.cid);
        bodyFormData.append("lat", localStorage.getItem('lat'));
        bodyFormData.append("lng", localStorage.getItem('long'));
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("date", this.state.date);
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        try {
            const resp = await axios
                .post(Constants.baseUrl + "content/csessions", bodyFormData)
                .then((resp) => {
                    if (resp.status === 200) {
                        this.setState({
                            output: resp.data.output,
                            img: resp.data.output.img,
                            dys: resp.data.output.bd,
                            childs: resp.data.output.childs,
                            loading: false,
                        });
                        console.log(resp);
                    }
                });
        } catch (err) {
            // Handle Error Here
            this.setState({
                loading: false,
            });
            console.error(err);
        }
    };
    componentDidMount() {
        const cinemaSessionsRequest = async () => {
            var bodyFormData = new FormData();
            bodyFormData.append("city", localStorage.getItem('city'));
            bodyFormData.append("cid", this.state.cid);
            bodyFormData.append("lat", localStorage.getItem('lat'));
            bodyFormData.append("lng", localStorage.getItem('long'));
            bodyFormData.append("userid", Constants.userid);
            bodyFormData.append("date", this.state.date);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/csessions", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (resp.data.result == "success") {
                                console.log(resp);
                                this.setState({
                                    output: resp.data.output,
                                    img: resp.data.output.img,
                                    dys: resp.data.output.bd,
                                    childs: resp.data.output.childs,
                                    loading: false,
                                })
                            } else {
                                this.setState({ errorMsg: resp.data.msg, showError: true });
                            }
                            // this.setState({ errorMsg: resp.data.msg, showError: true });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        cinemaSessionsRequest();
    }
    render() {
        return (
            <>
                <Header />
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false },
                        window.history.back())}
                />
                <div className="container">
                    <>
                        <Carousel>
                            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="d-block w-100" src={this.state.img} alt="loding..." />
                                        <div className="carousel-caption d-none d-md-block ">
                                            <h1 className='text'>{this.state.output.cn}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </>
                </div>
                <div className="container text">
                    <div className=" py-2">
                        {
                            this.state.dys.map((item, key) => {
                                return (
                                    <>
                                        <div className="date" onClick={() => this.changeDate(item.dt)}>
                                            <h6>{item.wd}</h6>
                                            <h6>{item.d}</h6>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container border-top border-bottom py-2">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <input type="text" className="search-bar w-50" placeholder='SEARCH CINEMAS.....' aria-label="Username" aria-describedby="basic-addon1" />
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <a className="nav-link text-success" href="#">AVAILABLE</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-warning" href="#">FILLING FAST</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-danger" href="#">SOLD OUT</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link disabled" href="#">LAPSED</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="container text">
                    {
                        this.state.childs.map((item, key) => {
                            return (
                                <>
                                    <hr></hr>
                                    <div className="">
                                        <h5>{item.ccn}</h5>
                                    </div>
                                    {
                                        item.mvs.map((item2, key) => {
                                            return (
                                                <>
                                                    <div className="">
                                                        <h6>{item2.mn}</h6>
                                                    </div>
                                                    {
                                                        item2.ml.map((item3, key) => {
                                                            return (
                                                                <>
                                                                    <p>{item3.lng}</p>
                                                                    {
                                                                        item3.s.map((item4, key) => {
                                                                            let url = "/seatlayout/" + item.ccn + "/" + this.state.output.mn + '?mcode=' + item4.mc + '&sessionid=' + item4.sid + '&cinemacode=' + item.ccid + '&screenId=' + item.sc + '&bundleAllowed=false&membersDay=false';
                                                                            let url2 = url.replace(/\s/g, "-")
                                                                            return (
                                                                                <>
                                                                                    <Link to={url2}>
                                                                                        <ul>
                                                                                            <li >{item4.st}</li>
                                                                                        </ul>
                                                                                    </Link>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </>
        )
    }
}