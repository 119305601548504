import React from 'react'
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
export default class SocialLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    responseGoogle = (response) => {
        console.log(response);
    }
    responseFacebook = (response) => {
        console.log(response);
    }
    render() {
        return (
            <>
                <GoogleLogin
                    clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <FacebookLogin
                    appId="1088597931155576"
                    fields="name,email,picture"
                    onClick={this.componentClicked}
                    callback={this.responseFacebook}
                    size="small" />
            </>
        )
    }
}