import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
export default function Protected(props) {
    const { Cmp } = props;
    const navigate = useNavigate()
    useEffect(() => {
        let login = localStorage.getItem('userid')
        if (!login) {
            navigate('/')
        }
    })
    return (
        <div><Cmp /></div>
    )
}