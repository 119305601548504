import React, { useState, useEffect } from 'react'
import axios from 'axios';
import * as Constants from '../Component/Constants/index'
export default function Search() {
    const [output, setOutput] = useState([])
    const [search, setSearch] = useState('NA')
    const [value, setValue] = useState('')

    const searchItem = (event) => {
        setValue(event.target.value)
        console.log(value)
    }
    const onSearch = (selectItem) => {
        setValue(selectItem)
    }
    useEffect(() => {
        var bodyFormData = new FormData();
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("search", search);
        const searchRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/search", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            setOutput(resp.data.output.m)
                        }
                    });
            } catch (err) {
                // Handle Error Here
                console.error("something wrong");
            }
        }
        searchRequest();
    }, [])

    return (
        <>
            <input className="search-bar" type="text" placeholder='Search movies or theatres...' value={value} onChange={searchItem} />
            <ul className="">
                {
                    output.filter(item => {
                        const search = value.toLowerCase()
                        const name = item.n.toLowerCase()
                        return search && name.startsWith(search) && name !== search
                    })
                        .map((item, key) => {
                            return (
                                <>
                                    <h6 className='text' onClick={() => onSearch(item.n)}>{item.n}</h6>
                                </>
                            )
                        })
                }
            </ul>
        </>
    )
}
