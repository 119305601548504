import React from "react";
import Header from "./Common/Header";
import Footer from "./Common/Footer"
import Banner from "./Banner"
import NowShowingHome from "./NowShowingHome";
import ComingSoon from "./ComingSoon";
import PrivilegeHomeCard from "./PrivilegeHomeCard";
export default function Home() {
  return (
    <>
      <Header />
      <Banner />
      <PrivilegeHomeCard />
      <NowShowingHome />
      <ComingSoon />
      <Footer />
    </>
  )
}