import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import NowShowing from "./NowShowing";
import ComingSoon from "./ComingSoon";
import ShowInfo from "./ShowInfo";
import Imax from "./Imax";
import Dx from "./Dx";
import Playhouse from "./Playhouse"
import Gold from "./Gold"
import Luxe from "./Luxe"
import Pxl from "./Pxl"
import Onyx from "./Onyx"
import Drive_in from "./Drive_in";
import DirectorsCut from "./DirectorsCut"
import MovieSessions from "./MovieSessions";
import SeatLayout from "./SeatLayout";
import Cinemas from "./Cinemas";
import GiftCard from "./GiftCard";
import Offers from "./Offers";
import MovieLibrary from "./MovieLibrary";
import MovieLibraryDetail from "./MovieLibraryDetail";
import Career from "./Career";
import Technology from "./Technology";
import Marketing from "./Marketing";
import Operations from "./Operations";
import PublicRelation from "./PublicRelations";
import News from "./News"
import Feedback from "./Feedback"
import Events from "./Events";
// import Login from "./Login";
import LoginWithOTP from "./LoginWithOTP";
import SignUp from "./SignUp";
// import SignupOtp from "./SignupOtp";
import SocialLogin from "./SocialLogin";
import GetFood from './GetFood'
import Payment from "./Payment";
import CinemaSessions from "./CinemaSessions";
import Protected from "./ProtectedRoutes";
import Loyalty from "./Loyalty";
export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: '',
      long: ''
    }
  }
  getlocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      localStorage.setItem('lat', lat)
      let long = position.coords.longitude;
      localStorage.setItem('long', long)
    });
  }
  componentDidMount() {
    this.getlocation();
  }
  render() {
    localStorage.setItem('city', 'Delhi-NCR')
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/nowshowing" element={<NowShowing />}></Route>
            <Route path="/comingsoon" element={<ComingSoon />}></Route>
            <Route path="/comingsoon/:city/:Moviename/:mid" element={<ShowInfo />}></Route>
            <Route path="/pvr/format/imax" element={<Imax />}></Route>
            <Route path="/pvr/format/4dx" element={<Dx />}></Route>
            <Route path="/pvr/format/play-house" element={<Playhouse />}></Route>
            <Route path="/pvr/format/gold" element={<Gold />}></Route>
            <Route path="/pvr/format/luxe" element={<Luxe />}></Route>
            <Route path="/pvr/format/pxl" element={<Pxl />}></Route>
            <Route path="/pvr/format/onyx" element={<Onyx />}></Route>
            <Route path="//pvr/format/dit" element={<Drive_in />}></Route>
            <Route path="/pvr/format/dircut" element={<DirectorsCut />}></Route>
            <Route path="/moviesessions/:city/:moviename/:mid" element={<MovieSessions />}></Route>
            <Route path="/seatlayout/:cinemaname/:moviename" element={<SeatLayout />}></Route>
            <Route path="/cinemas/Delhi-NCR" element={<Cinemas />}></Route>
            <Route path="/cinemasessions/:city/:cname/:cid" element={<CinemaSessions />}></Route>
            <Route path="/giftcards" element={<GiftCard />}></Route>
            <Route path="/offers" element={<Offers />}></Route>
            <Route path="/movie-library" element={<MovieLibrary />}></Route>
            <Route path="/movie-library/detail/:mid/:buffid" element={<MovieLibraryDetail />}></Route>
            <Route path="/careers" element={<Career />}></Route>
            <Route path="/careers/Technology" element={<Technology />}></Route>
            <Route path="/careers/Marketing" element={<Marketing />}></Route>
            <Route path="/careers/Operations" element={<Operations />}></Route>
            <Route path="/careers/PublicRelation" element={<PublicRelation />}></Route>
            <Route path="/news" element={<News />}></Route>
            <Route path="/feedback" element={<Feedback />}></Route>
            <Route path="/events" element={<Events />}></Route>
            {/* <Route path="/login" element={<Login />}></Route> */}
            <Route path="/login" element={<LoginWithOTP />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/sociallogin" element={<SocialLogin />}></Route>
            <Route path="/getfood/:cinemaname/:moviename" element={<Protected Cmp={GetFood} />}></Route>
            <Route path="/payment/:cinemaname" element={<Protected Cmp={Payment} />}></Route>
            <Route path="/loyalty" element={<Loyalty />}></Route>
          </Routes>
        </BrowserRouter >
      </>
    )
  }
} 