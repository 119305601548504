import React from "react"
import axios from "axios";
import * as Constants from "./Constants/index"
import { Carousel } from "react-responsive-carousel";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import Header from "../Component/Common/Header"
export default class ShowInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: {},
            cast: [],
            crew: [],
            videos: [],
            mid: window.location.href.split('/')[6],
            show: false,
            close: true,
            t: "",
            embedUrl: "",
            loading: true,
        }
    }
    //Model
    handleShow = (trailer) => {
        //        e.preventDefault()
        this.setState({ show: true, t: trailer, embedUrl: trailer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("mid", this.state.mid);
        bodyFormData.append("type", 'UP');
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        const ShowInfoRequest = async () => {

            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/getmovie", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                cast: resp.data.output.mb.cast,
                                crew: resp.data.output.mb.crew,
                                videos: resp.data.output.mb.videos,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        ShowInfoRequest();
    }
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <Carousel>
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={this.state.output.wib} alt="loding..." />
                                    <div className="carousel-caption d-none d-md-block ">
                                        <h1 >{this.state.output.n}</h1>
                                        <div>
                                            <span>{this.state.output.mopeningdate}.</span>
                                            <span>{this.state.output.lng}.</span>
                                        </div>
                                        <h4>{this.state.output.ca}</h4>
                                        <Button variant="primary" onClick={() => this.handleShow(this.state.output.t)}>
                                            PLAY TRAILER
                                        </Button>
                                    </div>
                                    <Modal
                                        show={this.state.show}
                                        onHide={this.state.handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ReactPlayer controls url={this.state.output.t} />
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="container text">
                    <p>{this.state.output.p}</p>
                    <h4>CAST</h4>
                    <div className="row">
                        {
                            this.state.cast.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-sm-2 ">
                                            <img src={item.poster} height="100px" alt="no image" className="rounded-circle" />
                                            <h6>{item.name}</h6>
                                            <span>{item.character}</span>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container text">
                    <h4>CREW</h4>
                    <div className="row">
                        {
                            this.state.crew.map((item, key) => {
                                return (
                                    <>
                                        {
                                            item.roles.map((item2, key) => {
                                                return (
                                                    <>
                                                        <div className="col-sm-2">

                                                            <div>
                                                                <img src={item2.poster} height="50px" alt="no image" />
                                                                <h6>{item2.name}</h6>
                                                                <span>{item2.role}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container text">
                    <h4>TRAILERS & VIDEOS</h4>
                    <div className="row ">
                        {
                            this.state.videos.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-md-3 mb-2">
                                            <img className="w-100" src={item.thumbnail} alt="no image" onClick={() => this.handleShow(item.embedUrl)} />
                                            <h6>{item.caption}</h6>
                                        </div>
                                        <Modal
                                            show={this.state.show}
                                            onHide={this.state.handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                            </Modal.Header>
                                            <Modal.Body>
                                                <ReactPlayer controls url={this.state.embedUrl} />
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}