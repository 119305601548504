import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import * as Constants from "./Constants/index"
import Header from './Common/Header'
import Footer from './Common/Footer'
export default function Career() {
    const [state, setState] = useState({})

    const navigate = useNavigate()
    function technology() {
        navigate("/careers/Technology", { state: { id: state.Technology } })
    }
    function marketing() {
        navigate("/careers/Marketing", { state: { id: state.Marketing } })
    }
    function operations() {
        navigate("/careers/Operations", { state: { id: state.Operations } })
    }
    function publicRelation() {
        navigate("/careers/PublicRelation", { state: { id: state['Public Relations'] } })
    }
    useEffect(() => {
        var bodyFormData = new FormData();
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        const careerRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "career/department", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp)
                            setState(resp.data.output)
                        }
                    })
            }
            catch (err) {
                // Handle Error Here
                console.error("something wrong");
            }
        }
        careerRequest();
    }, [])
    return (
        <>
            <Header />
            <div className="careerBg mb-5">
                <h1 className='careerHeading text'>Why PVR?</h1>
                <p className='careerPera text'>At PVR, we want you to be part of our open and lively environment, where every employee can make a contribution. We are always looking for individuals who share our passion for films and people - individuals who can work as part of a proactive team to deliver the best cinema experience to our guests.</p>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h4 className='career-link text' onClick={technology}>TECHNOLOGY</h4>
                    </div>
                    <div className="col-md-3">
                        <h4 className='career-link text' onClick={marketing}>MARKETING</h4>
                    </div>
                    <div className="col-md-3">
                        <h4 className='career-link text' onClick={operations}>OPERATIONS</h4>
                    </div>
                    <div className="col-md-3">
                        <h4 className='career-link text' onClick={publicRelation}>PUBLIC RELATIONS</h4>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}