import React from "react"
import Header from "./Common/Header"
import SimpleReactValidator from 'simple-react-validator';
import Modal from 'react-bootstrap/Modal';
import Footer from "./Common/Footer";
export default class GiftCard extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            name: "",
            email: "",
            mobile: "",
            textArea: "",
            show: false
        }
        this.handleName = this.handleName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleMobile = this.handleMobile.bind(this);
        this.handleTextArea = this.handleTextArea.bind(this);
    }
    handleName = (e) => {
        this.setState({ name: e.target.value });
    }
    handleEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    handleMobile = (e) => {
        this.setState({ mobile: e.target.value });
    }
    handleTextArea = (e) => {
        this.setState({ textArea: e.target.value });
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    payNow = () => {
        if (this.validator.allValid()) {
            this.setState({ show: true })
            console.log(`Name:-${this.state.name}   Email:-${this.state.email}  Mobile:-${this.state.mobile} Message:-${this.state.textArea}}`)
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    render() {
        return (
            <>
                <Header />
                <div className="container gift-container-bg text-light">
                    <h3>Movie Cards</h3>
                    <div className="col-10">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active text-light" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">BUY</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">CHECK BALANCE</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">BULK</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">TERMS</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form class="form-inline">
                                                <select class="custom-select my-1 mr-sm-2 w-100 bg-dark text-light" id="inlineFormCustomSelectPref">
                                                    <option value="2">PHYSICAL CARDS</option>
                                                    <option value="3">E-CARDS</option>
                                                </select>
                                            </form>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="container gift-card-form mb-5 bg-dark pt-3 pb-5">
                                                <div>This card is for</div><br></br>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label class="form-check-label" for="flexRadioDefault1">
                                                        Friends / Family
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                                    <label class="form-check-label" for="flexRadioDefault2">
                                                        Me
                                                    </label>
                                                </div>

                                                <input type="text" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.name} onChange={this.handleName} placeholder="Recipient Name"></input><br></br><br></br>
                                                {this.validator.message('Name', this.state.name, 'required')}
                                                <input type="email" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.email} onChange={this.handleEmail} placeholder="Enter Email"></input><br></br><br></br>
                                                {this.validator.message('Email', this.state.email, 'required|email')}
                                                <input type="number" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.mobile} onChange={this.handleMobile} placeholder="Enter Mobile"></input><br></br><br></br>
                                                {this.validator.message('mobile', this.state.mobile, 'required|min:10')}
                                                <textarea className="form-control bg-dark text-light giftcard-form-border" id="exampleFormControlTextarea1" value={this.state.textArea} onChange={this.handleTextArea} placeholder="Message"></textarea><br></br><br></br>
                                                <div>Total<span>₹0</span></div><br></br>
                                                <button type="button" onClick={this.payNow} class="btn btn-warning w-100">PAY NOW</button>
                                                <span>Discount will be applied on the summary page</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.show}
                    onHide={this.state.handleClose}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                </Modal>
                <Footer />
            </>
        )
    }
}