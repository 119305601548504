import React from "react"
import axios from "axios";
import * as Constants from "./Constants/index";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import Header from "../Component/Common/Header";
import Footer from "./Common/Footer";
export default class NowShowingHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            show: false,
            close: true,
            mtrailerurl: "",
            genres: [],
            filterGenreSelected: '',
            format: [],
            filterFormatSelected: '',
            language: [],
            filterLangSelected: '',
            loading: true
        }
    }
    //modal
    handleShow = (trailer) => {
        this.setState({ show: true, mtrailerurl: trailer })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("type", "DC");
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("dtmsource", 'null');

        const nowShowingRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/nowshowing", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output.mv[0],
                                loading: false,
                            });
                            let genres = [];
                            resp.data.output.mv[0].map((item, key) => {
                                let index = genres.indexOf(item.tag);
                                if (index == -1)
                                    genres.push(item.tag);
                            })
                            let format = [];
                            resp.data.output.mv[0].map((item, key) => {
                                item.fmts.map((item2, key) => {
                                    let index = format.indexOf(item2);
                                    if (index == -1)
                                        format.push(item2);
                                })
                            })
                            let language = [];
                            resp.data.output.mv[0].map((item, key) => {
                                let index = language.indexOf(item.lng);
                                if (index == -1)
                                    language.push(item.lng);
                            })
                            this.setState({ genres });
                            this.setState({ format });
                            this.setState({ language });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        nowShowingRequest();
    }
    filterGenres = (event) => {
        this.setState({ filterGenreSelected: event.target.value })
    }
    filterFormat = (event) => {
        this.setState({ filterFormatSelected: event.target.value })
    }
    filterLanguage = (event) => {
        this.setState({ filterLangSelected: event.target.value })
    }
    render() {
        let filterGenreSelected = this.state.filterGenreSelected
        let filterFormatSelected = this.state.filterFormatSelected
        let filterLangSelected = this.state.filterLangSelected
        return (
            <>
                <Header />
                <div className="container">
                    <div className="movie-library mb-5 mt-5">
                        <div className="movies">
                            <h2 className="text">NOW SHOWING</h2>
                        </div>
                        <div className="mt-3">
                            <select onChange={this.filterGenres} className='selectBox' >
                                <option value="" className="text-dark">ALL GENRES</option>
                                {
                                    this.state.genres.map((g, key) => {
                                        return (
                                            <>
                                                <option className="text-dark">{g}</option>
                                            </>
                                        )
                                    })}
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <select onChange={this.filterFormat} className='selectBox' >
                                <option value="" className="text-dark">ALL FORMAT</option>
                                {
                                    this.state.format.map((f, key) => {
                                        return (
                                            <>
                                                <option className="text-dark">{f}</option>
                                            </>
                                        )
                                    })}
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <select onChange={this.filterLanguage} className='selectBox' >
                                <option value="" className="text-dark">ALL LANGUAGE</option>
                                {
                                    this.state.language.map((l, key) => {
                                        return (
                                            <>
                                                <option className="text-dark">{l}</option>
                                            </>
                                        )
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {
                            this.state.output.map((item, key) => {
                                return (
                                    <>
                                        {
                                            item.fmts.map((item2, key) => {
                                                let url = "/moviesessions/" + localStorage.getItem('city') + "/" + item.n + "/" + item.id + "?language=" + item.lng
                                                let url2 = url.replace(/\s/g, "-")
                                                if ((item.tag === filterGenreSelected || filterGenreSelected === '') && (item2 === filterFormatSelected || filterFormatSelected === '') && (item.lng === filterLangSelected || filterLangSelected === '')) {
                                                    return (
                                                        <>
                                                            <div className="col-md-3">
                                                                <div className="card bg-dark mb-4 pb-2">
                                                                    <div className="card-body">
                                                                        <img src={item.wit} alt="" srcSet="" className="mw-100" />
                                                                        <div className="movie-name mt-1">
                                                                            <h6 className="text">{item.n}</h6>
                                                                            <h6 className="text">{item2}</h6>
                                                                            <span className='text'>{item.lng} . {item.ce} . {item.tag}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mx-0.5'>
                                                                        <Link to={url2}>
                                                                            <Button variant="light">
                                                                                BOOK TICKETS
                                                                            </Button>
                                                                        </Link>&nbsp;
                                                                        <Button variant="light" onClick={() => this.handleShow(item.mtrailerurl)}>
                                                                            PLAY TRAILER
                                                                        </Button>
                                                                        <Modal
                                                                            show={this.state.show}
                                                                            onHide={() => this.setState({ show: false })}
                                                                            dialogClassName="modal-90w"
                                                                            aria-labelledby="example-custom-modal-styling-title"
                                                                        >
                                                                            <Modal.Header closeButton>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <ReactPlayer controls url={this.state.mtrailerurl} />
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
