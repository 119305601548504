import React from "react"
import Header from "./Common/Header";
import axios from "axios";
import * as Constants from "./Constants/index"
import { Carousel } from "react-responsive-carousel";
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import noImage from '../assets/images/backgrounds/no-image.webp'
export default class MovieLibraryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: {},
            cast: [],
            crew: [],
            videos: [],
            tracks: [],
            video: {},
            techDetails: [],
            city: "",
            mid: window.location.href.split('/')[5],
            buffid: window.location.href.split('/')[6],
            show: false,
            close: true,
            embedUrl: "",
            castVisible: 4,
            crewVisible: 4,
            loading: true
        }
    }
    //Model
    handleShow = (trailer) => {
        //        e.preventDefault()
        this.setState({ show: true, embedUrl: trailer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    showMoreCast = () => {
        this.setState((old) => {
            return { castVisible: old.castVisible + 1000 }
        })
    }
    showMoreCrew = () => {
        this.setState((old) => {
            return { crewVisible: old.crewVisible + 100 }
        })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("city", "undefined");
        bodyFormData.append("mid", this.state.mid);
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("buffid", this.state.buffid);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("did", Constants.did);

        const MovieLibraryRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/getmoviedetail", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                cast: resp.data.output.mb.cast,
                                crew: resp.data.output.mb.crew,
                                videos: resp.data.output.mb.videos,
                                tracks: resp.data.output.mb.tracks,
                                video: resp.data.output.mb.tracks.video,
                                techDetails: resp.data.output.mb.techDetails,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        MovieLibraryRequest();
    }

    render() {
        let crewcount = this.state.crewVisible;
        let i = 0;

        return (
            <>
                <Header />
                <div className="container">
                    <Carousel className="container">
                        <div id="carouselExampleControls" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={this.state.output.wib} alt="" />
                                    <div className="carousel-caption d-none d-md-block ">
                                        <h5 className="ml-auto">{this.state.output.n}</h5>
                                        <span>{this.state.output.c}.{this.state.output.l}.{this.state.output.lng}.{this.state.output.genre}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="container">
                    <p className="text">{this.state.output.p}</p>
                    <h4 className="text">CAST</h4>
                    <div className="row">
                        {
                            this.state.cast.slice(0, this.state.castVisible).map((item, key) => {
                                return (
                                    <>
                                        <div className="col-sm-2 ">
                                            <img src={item.poster ? item.poster : noImage} height="100px" alt="no image" className="rounded-circle" />
                                            <h6 className="text">{item.name}</h6>
                                            <span className="text">{item.character}</span>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <button className="btn btn-view text" onClick={() => this.showMoreCast()}>Show More</button>
                    </div>
                </div>
                <div className="container">
                    <h4 className="text">CREW</h4>
                    <div className="row">
                        {
                            this.state.crew.map((item, key) => {
                                return (
                                    <>
                                        {
                                            item.roles.slice(0, this.state.crewVisible).map((item2, key) => {
                                                i += 1;
                                                if (i <= crewcount) {
                                                    return (
                                                        <>
                                                            <div className="col-sm-2">
                                                                <div>
                                                                    <img src={item2.poster ? item2.poster : noImage} height="50px" alt="no image" />
                                                                    <h6 className="text">{item2.name}</h6>
                                                                    <span className="text">{item2.role}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                        {this.state.crewVisible < i ?
                            <button className="btn btn-view text" onClick={() => this.showMoreCrew()}>Show More</button> : ''}
                    </div>
                </div>
                <div className="container">
                    <h4 className="text">TRAILERS & VIDEOS</h4>
                    <div className="row ">
                        {
                            this.state.videos.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-md-3 mb-2">
                                            <img className="w-100" src={item.thumbnail} alt="no image" onClick={() => this.handleShow(item.embedUrl)} />
                                            <h6 className="text">{item.caption}</h6>
                                        </div>
                                        <Modal
                                            show={this.state.show}
                                            onHide={this.state.handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                            </Modal.Header>
                                            <Modal.Body>
                                                <ReactPlayer controls url={this.state.embedUrl} />
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container">
                    <h4 className="text">MUSIC CLIPS</h4>
                    <div className="row">
                        {
                            this.state.tracks.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-sm-2 mb-2">
                                            <iframe src={item.video && item.video.embedUrl ? item.video.embedUrl : ''} width="100%" alt="no image" />
                                            <p className="text">{item.name}</p>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container">
                    <h4 className="text">TECHNICAL DETAILS</h4>
                    <table class="table table-bordered table-dark">
                        {
                            this.state.techDetails.map((item, key) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr>
                                                <td scope="row" className="text">{item.name}:</td>
                                                <td className="text">Mark</td>
                                            </tr>
                                        </tbody>
                                    </>
                                )
                            })
                        }
                    </table>
                </div>
            </>
        )
    }
}
