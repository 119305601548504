import React from "react"
import Header from "./Common/Header";
import axios from "axios";
import * as Constants from "./Constants/index";
import Modal from 'react-bootstrap/Modal';
import Footer from './Common/Footer'
export default class Offers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            show: false,
            close: true,
            imageurl: "",
            loading: true
        }
    }
    //Model
    handleShow = (offer) => {
        //        e.preventDefault()
        this.setState({ show: true, imageurl: offer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("lang", Constants.lang);


        const OffersRequest = async () => {
            try {
                // const token = localStorage.getItem("token");

                const resp = await axios
                    .post(
                        Constants.baseUrl + "deals/webdeals",
                        bodyFormData
                    )
                    .then((resp) => {
                        //  casetype array

                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                loading: false,
                            });
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        OffersRequest();
    }
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <h1 className="text mt-3 mb-3">DEALS & EXCLUSIVES</h1>
                    <div className="row">
                        {
                            this.state.output.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-md-3 offer-img" >
                                            <img src={item.imageurl} alt="offer" srcSet="" onClick={() => this.handleShow(item.imageurl)} />
                                        </div>
                                        <Modal
                                            show={this.state.show}
                                            onHide={this.state.handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                        >
                                            <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                            </Modal.Header>
                                            <Modal.Body style={{ height: "100vh", overflowY: 'scroll' }}>
                                                <img src={this.state.imageurl} alt="" srcSet="" />
                                                <h4>{item.name}</h4>
                                                <h5>How to avail the offer</h5>
                                                <ul>
                                                    <li>{item.howToAvail}</li>
                                                </ul>
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}



