import React from "react"
import axios from "axios";
import * as Constants from "./Constants/index"
import Modal from 'react-bootstrap/Modal'; import { FormSelect } from "react-bootstrap";
export default class Cities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            otherCity: [],
            show: false,
            close: true,
            value: 'Delhi-NCR',
            loading: true
        };
    }
    //modal
    handleShow = () => {
        this.setState({ show: true })
    }
    setcity = (city) => {
        this.setState({ value: city, show: false })
    }
    componentDidMount() {
        const CitiesRequest = async () => {
            var bodyFormData = new FormData();
            bodyFormData.append("lat", localStorage.getItem('lat'));
            bodyFormData.append("lng", localStorage.getItem('long'));
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("did", Constants.did);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("notifyid", 'jhdsfkjhdskjhf');
            bodyFormData.append("userid", Constants.userid);

            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/citieswithsub", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.setState({
                                output: resp.data.output.pc,
                                otherCity: resp.data.output.ot,
                                loading: false,
                            });

                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        CitiesRequest();
    }
    render() {
        return (
            <>
                <input className="cities-bar" type="button" value={this.state.value} placeholder='Search city' onClick={() => this.handleShow()}></input>
                <Modal
                    show={this.state.show}
                    onHide={() => this.setState({ show: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <h5>Select City</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Popular Cities</h5>
                        <div className="d-flex justify-content-center" style={{ width: "fit-content" }}>
                            {
                                this.state.output.map((item, key) => {
                                    return (
                                        <>
                                            <div className="mx-3">
                                                <img id="image" src={item.image} alt="" srcSet="" />
                                                <p onClick={() => this.setcity(item.name)}>{item.name}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <h5>Other Cities</h5>
                        <div className="d-flex justify-content-center flex-wrap " style={{ width: "fit-content" }}>
                            {
                                this.state.otherCity.map((item, key) => {
                                    return (
                                        <>
                                            <div>
                                                <ul>
                                                    <li className=" d-block text-align-center text-decoration-none" onClick={() => this.setcity(item.name)}>{item.name}</li>
                                                </ul>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}