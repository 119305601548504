import React from "react"
import * as Constants from "./Constants/index"
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import SimpleReactValidator from 'simple-react-validator';
import Button from 'react-bootstrap/Button';
import { sha512 } from 'js-sha512';
export default class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            output: {},
            selectedSeat: [],
            price: [],
            name: '',
            email: '',
            mobile: '',
            loading: true,
            showError: false,
            errorMsg: "",
            otpShow: false,
            otp: "",
            show: true,
            isError: {
                name: '',
                email: '',
                mobile: '',
            }
        }
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMobile = this.handleChangeMobile.bind(this);
    }
    handleChangeName = (e) => {
        this.setState({ name: e.target.value });
    }
    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    handleChangeMobile = (e) => {
        this.setState({ mobile: e.target.value });
    }

    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("transid", localStorage.getItem('transid'));
        bodyFormData.append("cinemacode", localStorage.getItem('ccode'));
        bodyFormData.append("userid", localStorage.getItem('user_id'));
        bodyFormData.append("bookingid", localStorage.getItem('bookingid'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("pt", Constants.pt);
        const ticketRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "trans/tckdetails", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (resp.data.result == "success") {
                                this.setState({
                                    output: resp.data.output,
                                    selectedSeat: resp.data.output.seat,
                                    price: resp.data.output.f
                                })
                                console.log(resp);
                            }
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        }
        ticketRequest();
    }
    // registerpay = () => {
    //     if (this.validator.allValid()) {
    //         var bodyFormData = new FormData();
    //         bodyFormData.append("name", this.state.name);
    //         bodyFormData.append("email", this.state.email);
    //         bodyFormData.append("mobile", this.state.mobile);
    //         bodyFormData.append("password", 'null');
    //         bodyFormData.append("av", Constants.av);
    //         bodyFormData.append("pt", Constants.pt);
    //         bodyFormData.append("did", Constants.did);
    //         const SignUpRequest = async () => {
    //             try {
    //                 const resp = await axios
    //                     .post(Constants.baseUrl + "user/new/register", bodyFormData)
    //                     .then((resp) => {
    //                         if (resp.status === 200) {
    //                             if (resp.data.result == "success") {
    //                                 console.log(resp);
    //                                 this.setState({
    //                                     loading: false,
    //                                 });
    //                             }
    //                         }
    //                     });
    //             } catch (err) {
    //                 // Handle Error Here
    //                 this.setState({
    //                     loading: false,
    //                 });
    //                 console.log("something Wrong");
    //             }
    //         };
    //         SignUpRequest();
    //     }
    //     else {
    //         this.validator.showMessages();
    //         // rerender to show messages for the first time
    //         // you can use the autoForceUpdate option to do this automatically`
    //         this.forceUpdate();
    //     }
    // }
    logIn = (e) => {
        if (this.validator.allValid()) {
            var bodyFormData = new FormData();
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("city", localStorage.getItem('city'));
            bodyFormData.append("cname", Constants.cname);
            const LoginwithOTPRequest = async () => {
                try {
                    const resp = await axios
                        .post(Constants.baseUrl + "v2/user/login", bodyFormData)
                        .then((resp) => {
                            if (resp.status === 200) {
                                if (resp.data.result == "success") {
                                    if ((resp.data.output.otp_require == "true") && (resp.data.output.nu == "false")) {
                                        this.setState({ otpShow: true });
                                    }
                                    else {
                                        var bodyFormData = new FormData();
                                        bodyFormData.append("name", this.state.name);
                                        bodyFormData.append("email", this.state.email);
                                        bodyFormData.append("mobile", this.state.mobile);
                                        bodyFormData.append("password", 'null');
                                        bodyFormData.append("av", Constants.av);
                                        bodyFormData.append("pt", Constants.pt);
                                        bodyFormData.append("did", Constants.did);
                                        bodyFormData.append("otp", this.state.otp);
                                        let token = this.state.email + "|" + this.state.mobile + "|" + this.state.otp;
                                        token = sha512(token);
                                        const SignUpRequest = async () => {
                                            try {
                                                const resp = await axios
                                                    .post(Constants.baseUrl + "user/new/register", bodyFormData, {
                                                        headers: {
                                                            "X-Token": token
                                                        }
                                                    })
                                                    .then((resp) => {
                                                        if (resp.status === 200) {
                                                            if (resp.data.result == "success") {
                                                                console.log(resp);
                                                                this.setState({
                                                                    loading: false,
                                                                });
                                                            }
                                                        }
                                                    });
                                            } catch (err) {
                                                // Handle Error Here
                                                this.setState({
                                                    loading: false,
                                                });
                                                console.log("something Wrong");
                                            }
                                        };
                                        SignUpRequest();
                                    }
                                    this.setState({ otpShow: true });
                                    console.log(resp);
                                    this.setState({
                                        loading: false,
                                    });
                                    this.setState({ errorMsg: resp.data.msg });
                                }
                            }
                        });
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            LoginwithOTPRequest();
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    verifyOTP = (e) => {
        if (this.validator.allValid()) {
            var bodyFormData = new FormData();
            bodyFormData.append("mobile", this.state.mobile);
            bodyFormData.append("otp", this.state.otp);
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("did", Constants.did);
            let token = this.state.mobile + "|" + this.state.otp;
            token = sha512(token);
            const LoginwithOTPRequest = async () => {
                try {
                    const resp = await axios
                        .post(Constants.baseUrl + "v2/user/verify", bodyFormData, {
                            headers: {
                                "X-Token": token
                            }
                        })
                        .then((resp) => {
                            console.log(resp);
                            if (resp.status === 200) {
                                if (resp.data.result == "success") {
                                    localStorage.setItem('user_email', (resp.data.output.em))
                                    localStorage.setItem('user_id', (resp.data.output.id))
                                    localStorage.setItem('user_phone', (resp.data.output.ph))
                                    localStorage.setItem('user_name', (resp.data.output.un))
                                    window.location.reload()
                                }
                                else {
                                    this.setState({ errorMsg: resp.data.msg, showError: true });
                                }
                            }
                            else {
                                this.setState({ errorMsg: "Something went wrong", showError: true });
                            }
                        });
                } catch (err) {
                    // Handle Error Here
                    this.setState({
                        loading: false,
                    });
                    console.log("something Wrong");
                }
            };
            LoginwithOTPRequest();
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
        this.setState({ otpShow: false })
    }
    render() {
        return (
            <>
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false })}
                />
                <div className="container text  pb-5">
                    <h5 className="text">YOUR CONTACT DETAILS</h5>
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <div className="row bg">
                                <div className="col-md-4">
                                    <div className="input-group input-group-outline mb-3">
                                        <input type="name" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.name == '' ? localStorage.getItem('user_name') : this.state.name} onChange={this.handleChangeName} placeholder="Full Name*" />
                                        {this.validator.message('Name', this.state.name, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group input-group-outline mb-3">
                                        <input type="email" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.email == '' ? localStorage.getItem('user_email') : this.state.email} onChange={this.handleChangeEmail} placeholder="Email*" />
                                        {this.validator.message('Email', this.state.email, 'required')}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group input-group-outline mb-3">
                                        <input type="tel" className="w-100 bg-dark text-light giftcard-form-border" value={this.state.mobile == '' ? localStorage.getItem('user_phone') : this.state.mobile} onChange={this.handleChangeMobile} placeholder="Mobile*" />
                                        {this.validator.message('Mobile', this.state.mobile, 'required|min:10|max:10')}
                                    </div>
                                </div>
                                {!localStorage.getItem('user_id') || localStorage.getItem('user_id') === '' || localStorage.getItem('user_id') === null || localStorage.getItem('user_id') === undefined ?
                                    <div className="col-md-12 col-sm-12">
                                        <div className="text-center">
                                            <button onClick={this.logIn} className="btn submit">Submit</button>
                                        </div>
                                    </div> : ''}

                            </div>
                            {/* {!localStorage.getItem('user_id') == '' || !localStorage.getItem('user_id') == null || !localStorage.getItem('user_id') == undefined ?
                                <div class="accordion mt-3 " id="accordionExample">
                                    <div class="accordion-item bg">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button bg text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Avail Offers
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body bg text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed  bg text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                PAYMENT OPTIONS
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body  bg text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed  bg text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Bank Offers
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body  bg text">
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''} */}
                        </div>
                        {
                            this.state.otpShow ?
                                <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Enter your OTP</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={(otp) => this.setState({ otp })}
                                            numInputs={6}
                                            isInputNum={true}
                                            separator={<span> </span>}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={() => this.verifyOTP()}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                : ''}
                        {!localStorage.getItem('user_id') == '' || !localStorage.getItem('user_id') == null || !localStorage.getItem('user_id') == undefined ?
                            <div className="col-md-4 col-sm-12">
                                <div className="container ticket_container bg-dark">
                                    <h5 className="pt-4 pb-5"><span>{this.state.output.m}</span><span>{this.state.output.audi}</span></h5>
                                    <h6>{this.state.output.c}</h6>
                                    <p className="pb-3">{this.state.output.t}</p>
                                    <hr></hr>
                                    <h6 >SEAT INFO </h6>
                                    {
                                        this.state.selectedSeat.map((item, key) => {
                                            return (
                                                <>
                                                    <div className="selectedSeat">
                                                        <span>{item.n}</span>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        this.state.price.map((item, key) => {
                                            return (
                                                <>
                                                    <div className="seat-info">
                                                        <div className="ticket">
                                                            <span>{item.n}</span>
                                                            {item.c > 0 ?
                                                                <span className="quantity">&nbsp;{item.c}</span> : ''}
                                                        </div>
                                                        <div className="amount">
                                                            <span>{item.v}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    <hr></hr>
                                </div>
                                <div className="total-amount">
                                    <div className="inr">
                                        <p>TOTAL[INR]</p>
                                    </div>
                                    <div className="payment">
                                        <p className="">{this.state.output.a}</p>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
            </>
        )
    }
}