import React from "react"
import Header from "./Common/Header";
import axios from "axios";
import * as Constants from "./Constants/index"
import { Carousel } from "react-responsive-carousel";
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import small_shield from "../assets/images/covid/small-shield.svg"
import mask from "../assets/images/covid/mask.svg"
import alchol from "../assets/images/covid/alcohol.svg"
import thermometer from "../assets/images/covid/thermometer.svg"
import { Link } from "react-router-dom";
export default class MovieSessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: {},
            cinemas: [],
            cast: [],
            crew: [],
            videos: [],
            dys: [],
            latitude: '',
            longitude: '',
            date: "NA",
            mid: (window.location.href.split('/')[6]).split('?')[0],
            show: false,
            close: true,
            vdo: "",
            loading: true,
        };
    }
    changeDate = (date) => {

        this.setState({ date }, this.MovieSessionsRequestonDateChange);
    }
    MovieSessionsRequestonDateChange = async () => {
        var bodyFormData = new FormData();
        bodyFormData.append("lat", localStorage.getItem('lat'));
        bodyFormData.append("lng", localStorage.getItem('long'));
        bodyFormData.append("city", localStorage.getItem('city'));
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("userid", Constants.userid);
        bodyFormData.append("date", this.state.date);
        bodyFormData.append("mid", this.state.mid);

        try {
            const resp = await axios
                .post(Constants.baseUrl + "content/msessionsnew", bodyFormData)
                .then((resp) => {
                    if (resp.status === 200) {
                        this.setState({
                            output: resp.data.output,
                            cinemas: resp.data.output.cinemas,
                            // cast: resp.data.output.mb && resp.data.output.mb.cast ? resp.data.output.mb.cast : [],
                            // crew: resp.data.output.mb && resp.data.output.mb.crew ? resp.data.output.mb.crew : [],
                            // videos: resp.data.output.mb && resp.data.output.mb.videos ? resp.data.output.mb.videos : [],
                            // loading: false,
                        });
                        console.log(resp);
                    }
                });
        } catch (err) {
            // Handle Error Here
            this.setState({
                loading: false,
            });
            console.error(err);
        }
    };
    //Model
    handleShow = (trailer) => {
        this.setState({ show: true, vdo: trailer })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    componentDidMount() {
        const MovieSessionsRequest = async () => {
            var bodyFormData = new FormData();
            bodyFormData.append("lat", localStorage.getItem('lat'));
            bodyFormData.append("lng", localStorage.getItem('long'));
            bodyFormData.append("city", localStorage.getItem('city'));
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("did", Constants.did);
            bodyFormData.append("userid", Constants.userid);
            bodyFormData.append("date", this.state.date);
            bodyFormData.append("mid", this.state.mid);

            try {
                const resp = await axios
                    .post(Constants.baseUrl + "content/msessionsnew", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.setState({
                                output: resp.data.output,
                                cinemas: resp.data.output.cinemas,
                                dys: resp.data.output.dys,
                                cast: resp.data.output.mb && resp.data.output.mb.cast ? resp.data.output.mb.cast : [],
                                crew: resp.data.output.mb && resp.data.output.mb.crew ? resp.data.output.mb.crew : [],
                                videos: resp.data.output.mb && resp.data.output.mb.videos ? resp.data.output.mb.videos : [],
                                loading: false,
                            });
                            console.log(resp);
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.error(err);
            }
        };
        MovieSessionsRequest();
    }
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <Carousel>
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={this.state.output.wib} alt="loding..." />
                                    <div className="carousel-caption d-none d-md-block ">
                                        <h1 >{this.state.output.nm}<span>{this.state.output.cert}</span></h1>
                                        <div>
                                            <span>{this.state.output.cert}.</span>
                                            <span>{this.state.output.len}.</span>
                                            <span>{this.state.output.lng}.</span>
                                            <span>{this.state.output.gnr}</span>
                                        </div>
                                        <div>
                                            <span>{this.state.output.ca}</span>
                                        </div>
                                        <button type="button" className="btn btn-dark" onClick={() => this.handleShow(this.state.output.vdo)}>Trailer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <Modal
                    show={this.state.show}
                    onHide={this.state.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer controls url={this.state.vdo} />
                    </Modal.Body>
                </Modal>
                <div className="container text">
                    <div className="border-top border-bottom py-2">
                        <h4><img src={small_shield} alt="no image" />Your Safety is our Top Priority</h4>
                        <div className="row ">
                            <div className="col-xs-12 col-sm-4 ">
                                <img src={mask} alt="no image" />
                                <h5>Use of Masks</h5>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <img src={alchol} alt="no image" />
                                <h5>Sanitization After Each Show</h5>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <img src={thermometer} alt="no image" />
                                <h5>Mandatory Temperature Checks</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container text">
                    <ul className="nav nav-pills mb-3 pt-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">SHOWTIMES</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">SYNOPSIS</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="container">
                                <div className="border-top border-bottom py-2">
                                    <Carousel>
                                        {
                                            this.state.dys.map((item, key) => {
                                                return (
                                                    <>
                                                        <div className="date" onClick={() => this.changeDate(item.dt)}>
                                                            <h6>{item.wd}</h6>
                                                            <div>{item.d}</div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>
                            </div>
                            <div className="container">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <input type="text" className="search-bar w-50" placeholder='SEARCH CINEMAS.....' aria-label="Username" aria-describedby="basic-addon1" />
                                        <ul className="navbar-nav mr-auto">
                                            <li className="nav-item active">
                                                <a className="nav-link text-success" href="#">AVAILABLE</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-warning" href="#">FILLING FAST</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-danger" href="#">SOLD OUT</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link disabled" href="#">LAPSED</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="container">
                                {
                                    this.state.cinemas.map((item, key) => {
                                        return (
                                            <>
                                                <div className="border-top border-bottom py-3">
                                                    <h4>{item.cn}</h4>
                                                    {
                                                        item.childs.map((childs, key) => {
                                                            return (
                                                                childs.sws.map((item2, key) => {
                                                                    return (
                                                                        <>
                                                                            <h6>{childs.ccn}</h6>
                                                                            <p>{item2.lng}</p>
                                                                            {
                                                                                item2.s.map((item3, key) => {
                                                                                    let url = "/seatlayout/" + item.cn + "/" + this.state.output.nm + '?mcode=' + item3.mc + '&sessionid=' + item3.sid + '&cinemacode=' + childs.ccid + '&screenId=' + item3.sn + '&bundleAllowed=false&membersDay=false';
                                                                                    let url2 = url.replace(/\s/g, "-")
                                                                                    return (
                                                                                        <>
                                                                                            <Link to={url2}>
                                                                                                <ul>
                                                                                                    <li >{item3.st}</li>
                                                                                                </ul>
                                                                                            </Link>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            )
                                                        })}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="container">
                                <p>{this.state.output.p}</p>
                                <h4>CAST</h4>
                                <div className="row">
                                    {
                                        this.state.cast.map((item, key) => {
                                            return (
                                                <>
                                                    <div className="col-sm-2 ">
                                                        <img src={item.poster} height="100px" alt="no image" className="rounded-circle" />
                                                        <h6>{item.name}</h6>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="container">
                                <h4>CREW</h4>
                                <div className="row">
                                    {
                                        this.state.crew.map((item, key) => {
                                            return (
                                                <>
                                                    {
                                                        item.roles.map((item2, keyboard) => {
                                                            return (
                                                                <>
                                                                    <div className="col-sm-2">
                                                                        <div>
                                                                            <img src={item2.poster} height="50px" alt="no image" />
                                                                            <h6>{item2.name}</h6>
                                                                            <span>{item2.role}</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="container">
                                <h4>TRAILERS & VIDEOS</h4>
                                <div className="row ">
                                    {
                                        this.state.videos.map((item, key) => {
                                            return (
                                                <>
                                                    <div className="col-md-3 mb-2">
                                                        <img className="w-100" src={item.thumbnail} alt="no image" onClick={() => this.handleShow(item.embedUrl)} />
                                                        <h6>{item.caption}</h6>
                                                    </div>
                                                    <Modal
                                                        show={this.state.show}
                                                        onHide={this.state.handleClose}
                                                        backdrop="static"
                                                        keyboard={false}
                                                    >
                                                        <Modal.Header closeButton onClick={() => this.setState({ show: false })} >
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <ReactPlayer controls url={this.state.embedUrl} />
                                                        </Modal.Body>
                                                    </Modal>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
