import React from "react"
import Header from "./Common/Header";
import axios from "axios";
import * as Constants from "./Constants/index"
import { Link } from "react-router-dom";
import Footer from './Common/Footer'
export default class MovieLibrary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: [],
            cityid: "0",
            loading: true,
            genres: [],
            filterGenreSelected: '',
            language: [],
            filterLangSelected: ''
        }
    }
    componentDidMount() {
        var bodyFormData = new FormData();
        bodyFormData.append("cityid", this.state.cityid);
        bodyFormData.append("cityname", localStorage.getItem('city'));
        bodyFormData.append("userId", Constants.userid);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("av", Constants.av);

        const MovieLibraryRequest = async () => {
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "screening/get/movielibrary", bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log(resp);
                            this.setState({
                                output: resp.data.output,
                                loading: false
                            });
                            let genres = [];
                            resp.data.output.map((item, key) => {
                                let index = genres.indexOf(item.gen);
                                if (index == -1)
                                    genres.push(item.gen);
                            })
                            let language = [];
                            resp.data.output.map((item, key) => {
                                let index = language.indexOf(item.lan);
                                if (index == -1)
                                    language.push(item.lan);
                            })
                            this.setState({ genres });
                            this.setState({ language });
                            // console.log(genres)
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        MovieLibraryRequest();
    }
    filterGenres = (event) => {
        this.setState({ filterGenreSelected: event.target.value })
    }
    filterLanguage = (event) => {
        this.setState({ filterLangSelected: event.target.value })
    }
    render() {
        let filterGenreSelected = this.state.filterGenreSelected
        let filterLangSelected = this.state.filterLangSelected
        return (
            <>
                <Header />
                <div className="container">
                    <div className="movie-library mb-5 mt-5">
                        <div className="movies">
                            <h3 className="text">Movie Library</h3>
                        </div>
                        <div className="mt-3">
                            <select onChange={this.filterGenres} className='selectBox'>
                                <option value="" className="text-dark">ALL GENRES</option>
                                {
                                    this.state.genres.map((f, key) => {
                                        return (
                                            <>
                                                <option className="text-dark">{f}</option>
                                            </>
                                        )
                                    })}
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            <select onChange={this.filterLanguage} className='selectBox' >
                                <option value="" className="text-dark">ALL LANGUAGE</option>
                                {
                                    this.state.language.map((l, key) => {
                                        return (
                                            <>
                                                <option className="text-dark">{l}</option>
                                            </>
                                        )
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            {
                                this.state.output.map((index, key) => {
                                    let url = "/movie-library/detail/" + index.mid + "/" + index.mb;
                                    if ((index.gen == filterGenreSelected || filterGenreSelected == '') && (index.lan == filterLangSelected || filterLangSelected == '')) {
                                        return (
                                            <>
                                                <div className="col-md-2 col-sm-6 col-6 mb-2">
                                                    <Link to={url} className="text-decoration-none">
                                                        <img src={index.miv} alt="" srcset="" className="w-100 rounded" />
                                                        <div className="text-light mb-3">
                                                            <h6 className="movie-name mt-2">{index.mn}</h6>
                                                            <span className="movie-lan">{index.lan} . {index.gen} . {index.yr}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}