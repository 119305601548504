import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Common/Header'
import Footer from './Common/Footer'
export default function Operations() {
    const location = useLocation()
    return (
        <>
            <Header />
            <div className="container career-sector text">
                <h3>Operations</h3>
                <p>{location.state.id}</p>
            </div>
            <Footer />
        </>
    )
}