import React, { Component } from 'react'
import HeroLogo from '../assets/images/companies/herologo-pvr.png'
import home_screen from '../assets/images/companies/home-screen-static.png'
import pointsicon from '../assets/images/companies/pointsicon.png'
import vouchericon from '../assets/images/companies/vouche-icon.png'
export default class PrivilegeHomeCard extends Component {
    handleClose = () => {
        this.setState({ show: false })
    }
    Loyalty = () => {
        window.location = '/loyalty'
    }
    alreadyMember = () => {
        alert('Firstly login')
        window.location = '/login'
    }
    render() {
        return (
            <>
                {!localStorage.getItem('user_id') || localStorage.getItem('user_id') === '' || localStorage.getItem('user_id') === null || localStorage.getItem('user_id') === undefined ?
                    <div className="container privilege-card-logout">
                        <div className="row">
                            <div className="col-md-8 privilege-content">
                                <h4 className="text">Introducing an exclusive loyalty program that pays you back!</h4>
                                <button className="btn btn-light" onClick={this.Loyalty}>KNOW MORE</button>
                                <button className="btn member-btn" onClick={this.alreadyMember} >ALREADY A MEMBER?</button>
                            </div>
                            <div className="col-md-4 privelege-img">
                                <img src={HeroLogo} alt="" />
                            </div>
                        </div>
                    </div> :
                    <div className="container privilege-card-login">
                        <div className="row">
                            <div className="col-md-5 privilege-top-content">
                                <h4 className="text"> Hey {localStorage.getItem('user_name')}</h4>
                                <p className="text">Your PVR Privilege earnings</p>
                            </div>
                            <div className="col-md-7">
                                <div className="feature-box">
                                    <ul className="pointslist">
                                        <li className="li-content">
                                            <img src={pointsicon} alt="" />
                                            <p className="text">Points</p>
                                        </li>
                                        <li className="li-content">
                                            <img src={vouchericon} alt="" />
                                            <p className="text">Vouchers</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <hr className="text" />
                            <div className="privilege-login-card-img">
                                <img src={home_screen} alt="" />
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}
