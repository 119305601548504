import React from "react"
import axios from "axios";
import * as Constants from "./Constants/index"
import { Carousel } from "react-responsive-carousel";
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
export default class SeatLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            output: {},
            rows: [],
            loading: true,
            showError: false,
            errorMsg: "",
            selected: [],
            seats: [],
            ticketCount: 0,
            seatNum: ''
        }
    }
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const screenid = urlParams.get('screenId');
        var bodyFormData = new FormData();
        bodyFormData.append("av", Constants.av);
        bodyFormData.append("did", Constants.did);
        bodyFormData.append("pt", Constants.pt);
        bodyFormData.append("screenid", screenid);
        bodyFormData.append("bundle", 'false');

        const SeatLayoutRequest = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const cinemacode = urlParams.get('cinemacode');
            const sessionid = urlParams.get('sessionid');
            try {
                const resp = await axios
                    .post(Constants.baseUrl + "trans/getseatlayoutnew/" + cinemacode + "/" + sessionid, bodyFormData)
                    .then((resp) => {
                        if (resp.status === 200) {
                            if (resp.data.result === "success") {
                                localStorage.setItem('ccode', resp.data.output.cinemaCode)
                                localStorage.setItem('cinema_name', resp.data.output.cn)
                                localStorage.setItem('movie_name', resp.data.output.mn)
                                console.log(resp);
                                this.setState({
                                    output: resp.data.output,
                                    rows: resp.data.output.rows,
                                    loading: false,
                                });
                            }
                            else {
                                this.setState({ errorMsg: resp.data.msg, showError: true });
                            }
                        }
                    });
            } catch (err) {
                // Handle Error Here
                this.setState({
                    loading: false,
                });
                console.log("something wrong");
            }
        };
        SeatLayoutRequest();
    }
    inittrans = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const cinemacode = urlParams.get('cinemacode');
        const sessionid = urlParams.get('sessionid');
        const screenid = urlParams.get('screenId');

        try {
            var bodyFormData = new FormData();
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("did", Constants.did);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("screenid", screenid);
            bodyFormData.append("bundle", "false");
            bodyFormData.append("isMembersDay", "");
            const resp = axios
                .post(Constants.baseUrl + "trans/initTrans/" + cinemacode + "/" + sessionid, bodyFormData)
                .then((resp) => {
                    if (resp.status === 200) {
                        if (resp.data.result === "success") {
                            localStorage.setItem('transid', JSON.parse(resp.data.output.transid))
                            if (!localStorage.getItem('user_id')) {
                                alert('Firstly login')
                                window.location = '/login'
                            } else {
                                this.reserveseat();
                            }
                            console.log(resp);
                        }
                        else {
                            this.setState({ errorMsg: resp.data.msg, showError: true });
                        }
                    }
                });
        } catch (err) {
            // Handle Error Here
            this.setState({
                loading: false,
            });
            console.log("something wrong");
        }
    }
    reserveseat = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const cinemacode = urlParams.get('cinemacode');
        const sessionid = urlParams.get('sessionid');

        let reserve =
            '{ "sessionid": ' + JSON.stringify(sessionid) + ', "cinemacode":' + JSON.stringify(cinemacode) + ', "transid": ' + localStorage.getItem('transid') + ', "seats": ' + JSON.stringify(this.state.seats) + ' }';
        // console.log(this.state.seats);
        //  let reserve = '{ "sessionid": "17599", "cinemacode": "PACD", "transid": "3446875", "seats": [{ "seatBookingId": "0000000001|1|1|11", "priceCode": "0001" }] }';
        // return false;
        try {
            var bodyFormData = new FormData();
            bodyFormData.append("av", Constants.av);
            bodyFormData.append("did", Constants.did);
            bodyFormData.append("pt", Constants.pt);
            bodyFormData.append("reserve", reserve);
            const resp = axios
                .post(Constants.baseUrl + "trans/reserveseats/", bodyFormData)
                .then((resp) => {
                    if (resp.status === 200) {
                        if (resp.data.result === "success") {
                            console.log(resp);
                            localStorage.setItem('bookingid', resp.data.output.bookingid)
                            // alert("food")
                            if ((resp.data.output.fc === 'false') || (resp.data.output.nf === 'false')) {
                                window.location = '/payment/' + localStorage.getItem('cinema_name')
                            } else {
                                window.location = '/getfood/' + localStorage.getItem('cinema_name') + '/' + localStorage.getItem('movie_name')
                            }
                        }
                        else {
                            this.setState({ errorMsg: resp.data.msg, showError: true });
                        }
                    }
                });
        } catch (err) {
            // Handle Error Here
            this.setState({
                loading: false,
            });
            console.log("something wrong")
        }
    }
    render() {
        let seatSelection = (seatValue, seatNumber, areaNumber) => {
            this.setState({ seatNum: seatNumber })
            if (this.state.ticketCount === 10) {
                // this.setState({ showNextButton: 1 });
                let tctPrice = this.state.ticketPrice;
                console.log(areaNumber);
                let areaPrice = this.state.output.priceList[areaNumber];
                console.log(areaPrice);
                let tckCount = this.state.ticketCount;

                var element = document.getElementById(seatValue);
                let selectedSeatsArray = this.state.selected;
                if (selectedSeatsArray.includes(seatNumber) === true) {
                    var index = selectedSeatsArray.indexOf(seatNumber)
                    if (index !== -1) {
                        element.classList.remove("selected");
                        tctPrice -= parseFloat(areaPrice.price);
                        this.setState({ ticketPrice: tctPrice });
                        tckCount--;
                        this.setState({ ticketCount: tckCount });

                        selectedSeatsArray.splice(index, 1);
                        this.state.seatsToSet.splice(index, 1);
                        if (selectedSeatsArray.length === 0) {
                            // this.setState({ showNextButton: 0 });
                        }
                    }
                }
                swal('You can only book 10 tickets at a time.');
                return;
            }
            // this.setState({ showNextButton: 1 });
            let tctPrice = this.state.ticketPrice;
            console.log(areaNumber);
            let areaPrice = this.state.output.priceList[areaNumber];
            console.log(areaPrice);
            let tckCount = this.state.ticketCount;

            var element = document.getElementById(seatValue);
            let selectedSeatsArray = this.state.selected;
            if (selectedSeatsArray.includes(seatNumber) === true) {
                let index = selectedSeatsArray.indexOf(seatNumber)
                if (index !== -1) {
                    tctPrice -= parseFloat(areaPrice.price);
                    this.setState({ ticketPrice: tctPrice });
                    tckCount--;
                    this.setState({ ticketCount: tckCount });

                    selectedSeatsArray.splice(index, 1);
                    this.state.seats.splice(index, 1);
                    if (selectedSeatsArray.length === 0) {
                        // this.setState({ showNextButton: 0 });
                    }
                }
            } else {
                tctPrice += parseFloat(areaPrice.price);
                this.setState({ ticketPrice: tctPrice });
                tckCount++;
                this.setState({ ticketCount: tckCount });
                selectedSeatsArray.push(seatNumber);
                let seatObj = {};
                seatObj.priceCode = areaPrice.priceCode;
                seatObj.seatBookingId = seatValue;
                this.state.seats.push(seatObj);
            }
            this.setState({
                selected: selectedSeatsArray
            })
            console.log(this.state.selected);
            console.log(this.state.seats);
        }
        // let seatSelection2 = (seat) => {
        //     //  alert(sn);
        //     //  alert(seat.b);
        //     let json = { "seatBookingId": seat.b, "priceCode": seat.c };

        //     let sn = seat.sn;
        //     let selectedSeat = this.state.selected;
        //     let selectedSeat2 = this.state.seats;

        //     let item = selectedSeat.indexOf(sn);
        //     if (item > -1) { // only splice array when item is found

        //         // 2nd parameter means remove one item only
        //     }
        //     else
        //         selectedSeat.push(sn)

        //     // if (selectedSeat2.length == 0)
        //     //     selectedSeat2.push(json);
        //     // else {

        //     //     for (let index = 0; index <= selectedSeat2.length; index++) {

        //     //         let seating = selectedSeat2[index];
        //     //         console.log(seating);

        //     //         if (seating.seatBookingId === seat.b) {
        //     //             alert("already here");
        //     //             selectedSeat2.splice(index, 1);

        //     //         }
        //     //         else
        //     //             selectedSeat2.push(json)
        //     //     }
        //     // }
        //     this.setState({ selected: selectedSeat, seats: selectedSeat2 })
        //     // console.log(this.state.seats)
        // }
        let selectedSeat = this.state.selected;
        return (
            <>
                <SweetAlert
                    show={this.state.showError}
                    title={this.state.errorMsg}
                    html
                    text={this.state.errorMsg}
                    onConfirm={() => this.setState({ showError: false },
                        window.history.back()
                    )}
                />
                <div className="container">
                    <Carousel>
                        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-block w-100" src={this.state.output.iw} alt="loding..." />
                                    <div className="carousel-caption d-none d-md-block ">
                                        <h1 >{this.state.output.mn}</h1>
                                        <div>
                                            <span>{this.state.output.cn}.</span>
                                            <span>{this.state.output.st}.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <div className="container bg-dark">
                        <div className="text-center justify-content-center">
                            <p>&#8593;SCREEN THIS WAY</p>
                        </div>
                        <div className="box w-100 text-center justify-content-center">
                            <h4 className="d-inline-block mx-5 text-light">Available</h4>
                            <h4 className="d-inline-block mx-5 text-warning">Selected</h4>
                            <h4 className="d-inline-block mx-5 text-secondary">Unavailable</h4>
                        </div>
                        <table className="table">
                            {
                                this.state.rows.map((item, key) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className="text-warning"><b>{item.n}</b></td>
                                                {
                                                    item.s.map((seat, key) => {
                                                        if (seat.s === 1) {
                                                            if (selectedSeat.indexOf(seat.sn) > -1) {
                                                                return (
                                                                    <td className={"text-warning"} onClick={() => seatSelection(seat.b, seat.sn, seat.c)}>
                                                                        {seat.sn}
                                                                    </td>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <td className={"text-light"} onClick={() => seatSelection(seat.b, seat.sn, seat.c)}>
                                                                        {seat.sn}
                                                                    </td>
                                                                )
                                                            }
                                                        }
                                                        else {
                                                            return (
                                                                <td className={"text-secondary"} >
                                                                    {seat.sn}
                                                                </td>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                        </table>
                    </div>
                </div>
                <button className="btn btn-light bg-light position-fixed bottom-0 start-0" onClick={() => window.history.back()}>prev</button>
                {selectedSeat.length > 0 ?
                    <div className="container bg-light position-fixed bottom-0 end-0 foodDetails rounded-top">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="seat-info">
                                    <h5>Seat Info</h5>
                                    {
                                        this.state.selected.map((item, key) => {
                                            return (
                                                <>
                                                    <p>{item}</p>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 text-end">
                                <div className="now-cancel">
                                    <p> Now, With Cancellation</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="payment-infos">
                                    <div className="show-total">
                                        <p>TotaL [INR]</p>
                                    </div>
                                    <div className="amount-show">
                                        <p>Test</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                {selectedSeat.length > 0 ?
                    <div>
                        <button className="btn btn-warning position-fixed bottom-0 end-0" onClick={this.inittrans}>Next</button>
                    </div> : ''}
            </>
        )
    }
}